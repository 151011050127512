import Model from "../_model";
import PartnerModel from "./partner.model";

export default class CorporationModel extends Model {
	constructor(provider) {
		super(provider, "corporations");
		this.partners = new PartnerModel(provider);
	}

	setProvider(value) {
		super.setProvider(value);
		this.partners.setProvider(value);
	}

	getMyCorporation({ onSuccess, onFail }) {
		return super.filter({ url: "corporation", onSuccess, onFail });
	}
	getPdfTemplates({ onSuccess, onFail }) {
		const url = `pdf_templates`;
		return super._get(url, { onSuccess, onFail });
	}
	getPdfTemplate(corporationId, { onSuccess, onFail }) {
		const url = `${this._getUrl(corporationId)}/pdf_templates`;
		return super._get(url, { onSuccess, onFail });
	}
	addPdfTemplate(corporationId, templateData, { onSuccess, onFail }) {
		const url = `${this._getUrl(corporationId)}/pdf_templates`;
		return super._post(url, templateData, { onSuccess, onFail });
	}
	updatePdfTemplate(corporationId, templateId, templateData, { onSuccess, onFail }) {
		const url = `${this._getUrl(corporationId)}/pdf_templates/${templateId}`;
		return super._put(url, templateData, { onSuccess, onFail });
	}
	deletePdfTemplate(corporationId, templateId, { onSuccess, onFail }) {
		const url = `${this._getUrl(corporationId)}/pdf_templates/${templateId}`;
		return super._delete(url, { onSuccess, onFail });
	}
}
