<template>
	<v-container tag="section" aria-label="Benefits Section" class="container">
		<v-row class="d-flex align-center">
			<v-col
				cols="12"
				md="6"
				class="text-container text-center justify-center justify-md-start text-md-left"
				tag="section"
			>
				<v-card-subtitle
					:class="isMobile ? 'v-typography--title1' : 'v-typography--title2'"
					class="accent--text title-with-line pa-0 pl-1"
					tag="h2"
				>
					{{ $t(`$landing.$benefits.benefits_title`) }}
				</v-card-subtitle>

				<v-card-title
					:class="isMobile ? 'v-typography--title' : 'v-typography--title3'"
					class="pa-1 text-center justify-center text-md-left justify-md-start mt-2 mb-4"
					tag="h3"
				>
					<span v-html="formattedControlTitle"></span>
				</v-card-title>

				<v-card-subtitle
					:class="isMobile ? 'v-typography--body1' : 'v-typography--paragraph'"
					class="pa-1 mb-0 text-center justify-center text-md-left justify-md-start"
					tag="p"
				>
					{{ $t(`$landing.$benefits.text`) }}
				</v-card-subtitle>

				<v-list tag="ul" class="pl-0 bg_transparent">
					<v-list-item
						v-for="benefit in benefits"
						:key="benefit.text"
						:class="isMobile ? 'v-typography--body1' : 'v-typography--paragraph'"
						class="pa-1 d-flex align-center no-min-height text-center justify-center text-md-left justify-md-start"
						tag="li"
					>
						<img
							src="@/assets/red_check.webp"
							class="check-img mr-2"
							alt="Check icon indicating a benefit"
							loading="lazy"
							height="17"
							width="17"
						/>
						{{ $t(benefit.text) }}
					</v-list-item>
				</v-list>
			</v-col>
			<v-col cols="12" md="6" class="d-flex justify-center">
				<v-img
					src="https://fs.antrego.net/landing/benefits.webp"
					class="image-style"
					alt="Illustration showing the features and benefits of Antrego platform"
					loading="lazy"
				/>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {
	props: { isMobile: Boolean },
	data() {
		return {
			benefits: [
				{ icon: "icon-check", text: "$landing.$benefits.business_process" },
				{ icon: "icon-check", text: "$landing.$benefits.risk_of_error" },
				{ icon: "icon-check", text: "$landing.$benefits.time_and_cost_savings" },
			],
		};
	},
	computed: {
		highlightedWord() {
			const wordMap = {
				en: "control",
				tr: "kontrol",
			};
			const lang = this.$i18n.locale;
			return wordMap[lang] || "control";
		},
		formattedControlTitle() {
			const originalText = this.$t(`$landing.$benefits.control_title`);
			const highlighted = this.highlightedWord;
			const regex = new RegExp(`(${highlighted})`, "i");
			return originalText.replace(regex, '<span style="color: #B71C1C;">$1</span>');
		},
	},
};
</script>

<style scoped>
.image-style {
	width: 100%;
	max-width: 600px;
	height: auto;
}

.title-with-line {
	position: relative;
	display: inline-block;
}

.title-with-line::after {
	content: "";
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 2px;
	background-color: currentColor;
	transform: scaleX(0.4);
	transform-origin: left;
	margin-left: 4px;
}

.check-img {
	width: 17px;
	height: 17px;
}

.v-main {
	max-width: 100%;
	overflow-x: hidden;
}
@media (min-width: 1904px) {
	.container {
		max-width: 1185px !important;
	}
}
@media (max-width: 960px) {
	.title-with-line::after {
		height: 0px;
	}
}

@media (max-width: 375px) {
	.image-style {
		max-width: 100%;
	}

	.text-h5 {
		font-size: 1.25rem;
	}

	.text-h6 {
		font-size: 1.125rem;
	}

	.text-h8 {
		font-size: 1rem;
	}

	.text-body-1 {
		font-size: 0.875rem;
	}

	.text-body-2 {
		font-size: 0.75rem;
	}

	.check-img {
		width: 14px;
		height: 14px;
	}

	.pa-1 {
		padding: 0.5rem;
	}

	.mb-4 {
		margin-bottom: 1rem;
	}

	.w-100 {
		width: 100%;
	}

	.mx-auto {
		margin-left: auto;
		margin-right: auto;
	}
}
</style>
