<template>
	<v-dialog v-model="value" fullscreen persistent @input="onDialogClosed">
		<v-card class="p-0">
			<v-toolbar flat>
				<v-toolbar-title>{{ $t("pdf_editor") }}</v-toolbar-title>
				<v-spacer></v-spacer>
				<v-btn v-if="isIframeReady" icon @click="onDialogClosed">
					<v-icon>icon-cross</v-icon>
				</v-btn>
			</v-toolbar>

			<v-divider class="mb-4" />

			<v-toolbar flat dense class="mb-2 overflow-y-auto">
				<v-radio-group v-model="selectedCategory" :row="true" hide-details="">
					<v-radio
						:value="false"
						:label="$t('template')"
						class="ml-2"
						on-icon="icon-radio-on"
						off-icon="icon-radio-off"
					/>
					<v-radio
						:value="true"
						:label="$t('document')"
						class="ml-2"
						on-icon="icon-radio-on"
						off-icon="icon-radio-off"
						:disabled="documentTemplates.length === 0"
					/>
				</v-radio-group>
				<v-select
					:items="filteredTemplates"
					item-text="name"
					item-value="_id"
					:label="selectedCategory ? $t('document') : $t('template')"
					append-icon="icon-angle-down"
					v-model="selectedTemplateId"
					:loading="loadingTemplates"
					:disabled="loadingTemplates || filteredTemplates.length === 0 || !isIframeReady"
					class="mx-2 my-1"
					:menu-props="{ offsetY: true }"
					dense
					hide-details
					style="max-width: 200px"
				>
					<template v-slot:item="{ item }">
						{{ item.name }}
					</template>
				</v-select>

				<v-spacer />
				<v-select
					:items="availableLanguages"
					item-text="text"
					item-value="value"
					:label="$t('language')"
					append-icon="icon-angle-down"
					v-model="selectedLanguage"
					:disabled="!isIframeReady"
					class="mx-2 my-1"
					:menu-props="{ offsetY: true }"
					dense
					hide-details
					style="max-width: 100px"
				>
					<template v-slot:item="{ item }">
						{{ item.text }}
					</template>
				</v-select>
				<v-btn
					text
					outlined
					elevation="0"
					@click="deleteSelectedTemplate"
					:disabled="!isIframeReady || selectedTemplate?.origin === 'general'"
					class="mx-2 my-1"
					:title="selectedCategory ? $t('delete_document') : $t('delete_template')"
				>
					<v-icon color="red">icon-delete</v-icon>
					{{ selectedCategory ? $t("delete_document") : $t("delete_template") }}
				</v-btn>

				<v-btn color="primary" class="mx-2 my-1" @click="openAddTemplateDialog" :disabled="!isIframeReady">
					{{ $t("template_actions") }}
				</v-btn>
				<v-btn color="primary" @click="isSavePdfDialogVisible = true" :disabled="!isIframeReady">
					{{ $t("document_actions") }}
				</v-btn>
			</v-toolbar>

			<div>
				<iframe ref="reactIframe" :src="iframeSrc" frameborder="0" class="full-iframe"></iframe>
			</div>
		</v-card>

		<add-pdf-template-dialog
			:visibility.sync="isAddTemplateDialogVisible"
			:existingTemplates="corporationTemplates"
			:initialSelectedTemplateId="selectedTemplateId"
			@saved="onAddTemplateSaved"
		/>
		<save-pdf-dialog
			:visibility.sync="isSavePdfDialogVisible"
			:existing-docs="documentTemplates"
			:initial-selected-doc-id="
				documentTemplates.some((doc) => doc._id === selectedTemplateId) ? selectedTemplateId : null
			"
			@saved="onSavePdfDialogSaved"
		/>
	</v-dialog>
</template>

<script>
import remote from "../../../../data/remote";
import uiMixin from "../../../../store/interfaces/ui.mixin";
import { AccessModifier } from "../../../../utils/enum";
import AddPdfTemplateDialog from "./AddPdfTemplateDialog.vue";
import SavePdfDialog from "./SavePdfDialog.vue";
import { mapGetters, mapState } from "vuex";
const ALLOWED_ORIGIN = process.env.VUE_APP_PDF_CREATE_URL_ROOT;

export default {
	name: "DocumentPdfManager",
	mixins: [uiMixin],
	components: {
		AddPdfTemplateDialog,
		SavePdfDialog,
	},
	props: {
		value: { type: Boolean, required: true },
		documentType: { type: String, required: true },
		documentObject: { type: Object, required: true },
	},
	data() {
		return {
			iframeSrc: ALLOWED_ORIGIN,
			templates: [],
			selectedTemplateId: null,
			loadingTemplates: false,
			isIframeReady: false,
			selectedCategory: false,
			isAddTemplateDialogVisible: false,
			isSavePdfDialogVisible: false,

			pendingAddTemplateName: null,
			isSavingTemplate: false,
			isOverrideTemplate: false,
			overrideTemplateId: null,

			pendingPdfFilename: null,
			pendingAttachToDoc: false,
			pendingSaveTemplate: false,

			isOverrideDocument: false,
			overrideDocumentId: null,
			marginTop: 10,
			marginBottom: 10,
			selectedLanguage: "en",
			availableLanguages: [
				{ value: "ar", text: this.$t("$languageLocal.ar") },
				{ value: "de", text: this.$t("$languageLocal.de") },
				{ value: "en", text: this.$t("$languageLocal.en") },
				{ value: "es", text: this.$t("$languageLocal.es") },
				{ value: "fr", text: this.$t("$languageLocal.fr") },
				{ value: "hi", text: this.$t("$languageLocal.hi") },
				{ value: "ind", text: this.$t("$languageLocal.ind") },
				{ value: "it", text: this.$t("$languageLocal.it") },
				{ value: "ja", text: this.$t("$languageLocal.ja") },
				{ value: "ko", text: this.$t("$languageLocal.ko") },
				{ value: "pt", text: this.$t("$languageLocal.pt") },
				{ value: "ru", text: this.$t("$languageLocal.ru") },
				{ value: "tr", text: this.$t("$languageLocal.tr") },
				{ value: "vi", text: this.$t("$languageLocal.vi") },
				{ value: "zh", text: this.$t("$languageLocal.zh") },
			],
			translations: null,

			pendingDocumentFilename: null,
			pendingAttachmentFilename: null,
		};
	},
	computed: {
		...mapState(["corporation"]),
		...mapGetters(["company"]),
		filteredTemplates() {
			if (this.selectedCategory === null) {
				return [];
			} else if (this.selectedCategory) {
				return this.templates.filter((t) => t.origin === "document");
			} else {
				return this.templates.filter((t) => t.origin === "corporation" || t.origin === "general");
			}
		},
		selectedTemplate() {
			return this.templates.find((template) => template._id === this.selectedTemplateId) || null;
		},

		documentTemplates() {
			return this.templates.filter((template) => template.origin === "document");
		},

		corporationTemplates() {
			return this.templates.filter((template) => template.origin === "corporation");
		},
	},
	methods: {
		async loadTranslations() {
			try {
				const messages = await import(`../../../../plugins/i18n/${this.selectedLanguage}.js`);
				this.translations = messages.default;
			} catch (error) {
				console.error(`Failed to load translations for ${this.selectedLanguage}:`, error);
				const messages = await import(`../../../../plugins/i18n/en.js`);
				this.translations = messages.default;
			}
		},

		getTranslation(key) {
			if (!this.translations) {
				return key;
			}
			const segments = key.split(".");
			let result = this.translations;
			for (const segment of segments) {
				if (result && segment in result) {
					result = result[segment];
				} else {
					return key;
				}
			}
			return result || key;
		},

		getTranslationWithoutLanguage(key) {
			return this.$t(key);
		},
		translateStaticLabels(template) {
			const translatedTemplate = JSON.parse(JSON.stringify(template));

			const staticLabels = {
				head:
					this.documentType === "proforma"
						? "proforma_invoice"
						: this.documentType === "shipment"
						? "shipment"
						: "invoice",
				billedToLabel: "billed_to",
				invoiceNo: "invoice_no",
				terms: "terms_of_sale_and_other_comments",
				paymentInfoInput: "payment_information",
				terms_label: "terms_of_sale_and_other_comments",
				paymentInfoLabel: "payment_information",
				totalLabel: "total",
				loading: "loading_point",
				unloading: "unloading_point",
				shipmentMethod: "shipment_method",
				shippingTerm: "shipping_term",
				date: "date",
			};

			translatedTemplate.schemas.forEach((page) => {
				page.forEach((field) => {
					if (field.type === "text" && staticLabels[field.name]) {
						if (field.content === staticLabels[field.name]) {
							field.content = this.getTranslation(staticLabels[field.name]);
						}
					}
					if (field.type === "table" && Array.isArray(field.head) && field.name.startsWith("orders")) {
						const defaultHeads = ["product", "quantity", "unit", "total"];
						const isStillDefault =
							field.head.length === defaultHeads.length &&
							field.head.every((val, i) => [defaultHeads[i], this.getTranslation(defaultHeads[i])].includes(val));
						if (isStillDefault) {
							field.head = defaultHeads.map((h) => this.getTranslation(h));
						}
					}
				});
			});

			return translatedTemplate;
		},
		fetchAllPdfTemplates() {
			this.loadingTemplates = true;
			let allTemplates = [];
			const docTmpls = (this.documentObject.pdfTemplates || [])
				.slice()
				.reverse()
				.map((template) => ({
					...template,
					origin: "document",
				}));
			allTemplates.push(...docTmpls);

			remote.corporations.getPdfTemplate(this.corporation.id, {
				onSuccess: (corpTemplates) => {
					const corpTmpls = corpTemplates
						.slice()
						.reverse()
						.map((template) => ({
							...template,
							origin: "corporation",
						}));

					remote.corporations.getPdfTemplates({
						onSuccess: (generalTemplates) => {
							const genTmpls = generalTemplates
								.slice()
								.reverse()
								.map((tmpl) => ({
									...tmpl,
									origin: "general",
								}));

							allTemplates = [...allTemplates, ...corpTmpls, ...genTmpls];

							this.templates = allTemplates;
							const availableTemplates = this.filteredTemplates;
							if (availableTemplates.length > 0) {
								this.selectedTemplateId = availableTemplates[0]._id;
							} else {
								this.selectedTemplateId = null;
							}
							this.hideProgressBar();
							this.loadingTemplates = false;
						},
						onFail: (err) => {
							console.error("Error fetching general templates:", err);
							this.hideProgressBar();
							this.loadingTemplates = false;
						},
					});
				},
				onFail: (error) => {
					console.error("Error fetching corp templates:", error);
					this.hideProgressBar();
					this.loadingTemplates = false;
				},
			});
		},

		addPdfTemplate(template) {
			remote.corporations.addPdfTemplate(this.corporation.id, template, {
				onSuccess: () => {
					this.fetchAllPdfTemplates();
				},
				onFail: (error) => {
					console.error("Error adding PDF Template:", error);
				},
			});
		},

		overridePdfTemplate(templateId) {
			this.overrideTemplateId = templateId;
			this.isOverrideTemplate = true;
			this.saveTemplate();
		},

		onDialogClosed() {
			this.$emit("input", false);
			this.$emit("closed");
		},

		handleMessage(event) {
			if (event.origin + "/" !== ALLOWED_ORIGIN) {
				// console.warn("Received message from unauthorized origin vue:", event.origin);
				return;
			}
			const messageData = event.data || {};

			if (messageData.type === "READY") {
				this.isIframeReady = true;
				if (this.selectedTemplate) {
					this.loadTemplate();
				}
			}

			if (messageData.type === "LOADED") {
				this.hideProgressBar();
				this.isIframeReady = true;
			}

			if (messageData.type === "TEMPLATE_DATA") {
				const templatePayload = messageData.template;

				if (this.isSavingTemplate && this.pendingAddTemplateName) {
					const newTemplate = {
						name: this.pendingAddTemplateName,
						schemas: templatePayload.schemas,
						basePdf: templatePayload.basePdf,
					};
					this.pendingAddTemplateName = null;
					this.isSavingTemplate = false;
					this.addPdfTemplate(newTemplate);
				} else if (this.isOverrideTemplate) {
					const templateData = { schemas: templatePayload.schemas };
					remote.corporations.updatePdfTemplate(this.corporation.id, this.overrideTemplateId, templateData, {
						onSuccess: () => {
							this.fetchAllPdfTemplates();
							this.isOverrideTemplate = false;
						},
						onFail: (err) => {
							console.error("Error overriding PDF Template:", err);
							this.isOverrideTemplate = false;
						},
					});
				} else if (this.isOverrideDocument) {
					const templateData = { schemas: templatePayload.schemas };
					if (this.documentType === "proforma") {
						remote.proformaInvoices.updatePdfTemplate(this.documentObject.id, this.overrideDocumentId, templateData, {
							onSuccess: () => {
								this.$emit("change");
								this.onDialogClosed();
							},
							onFail: (err) => {
								console.error("Failed to override doc-based PDF template:", err);
							},
						});
					} else if (this.documentType === "shipment") {
						remote.shipments.updatePdfTemplate(this.documentObject.id, this.overrideDocumentId, templateData, {
							onSuccess: () => {
								this.$emit("change");
								this.onDialogClosed();
							},
							onFail: (err) => {
								console.error("Failed to override doc-based PDF template:", err);
							},
						});
					}
					this.isOverrideDocument = false;
					this.overrideDocumentId = null;
				} else if (this.pendingSaveTemplate) {
					const templateData = {
						name: this.pendingDocumentFilename,
						schemas: templatePayload.schemas,
						basePdf: templatePayload.basePdf,
					};
					const docCopy = { ...this.documentObject };
					if (!Array.isArray(docCopy.pdfTemplates)) {
						docCopy.pdfTemplates = [];
					}
					docCopy.pdfTemplates.push(templateData);
					this.addDocument(docCopy, {
						onSuccess: () => {
							this.$emit("change");
							this.onDialogClosed();
						},
						onFail: (err) => {
							console.error("Failed to update doc with new PDF template:", err);
						},
					});
					this.pendingSaveTemplate = false;
				} else {
					console.warn("Received TEMPLATE_DATA without a known operation.");
				}
			} else if (messageData.type === "PDF_DATA" && messageData.pdfBase64) {
				if (this.pendingAttachToDoc) {
					this.handlePdfData(messageData.pdfBase64);
					this.pendingAttachToDoc = false;
				}
			}
		},

		updateDocument(docData, { onSuccess, onFail }) {
			if (this.documentType === "proforma") {
				remote.proformaInvoices.updatePdfTemplate(this.documentObject.id, this.selectedTemplateId, docData, {
					onSuccess,
					onFail,
				});
			} else if (this.documentType === "shipment") {
				remote.shipments.updatePdfTemplate(this.documentObject.id, this.selectedTemplateId, docData, {
					onSuccess,
					onFail,
				});
			}
		},

		postMessageToIframe(message) {
			const iframe = this.$refs.reactIframe;
			if (iframe && iframe.contentWindow) {
				iframe.contentWindow.postMessage(message, ALLOWED_ORIGIN);
			} else {
				// console.error("Iframe not found");
			}
		},
		saveTemplate() {
			this.postMessageToIframe({ type: "SAVE_TEMPLATE" });
		},
		savePdf() {
			this.postMessageToIframe({ type: "SAVE_PDF" });
		},

		populateTemplate(template, documentObject) {
			if (template.origin === "document") {
				template = this.translateStaticLabels(template);
				return template;
			}
			let populatedTemplate = JSON.parse(JSON.stringify(template));

			const PAGE_HEIGHT = populatedTemplate.basePdf.height - this.marginBottom;
			let schemaObjects = [];
			let base_table = null;
			let secondTableHeight = 0;

			for (let i = 0; i < populatedTemplate.schemas.length; i++) {
				let schemaArrays = populatedTemplate.schemas[i];
				for (let j = 0; j < schemaArrays.length; j++) {
					let schemaObject = schemaArrays[j];
					if (schemaObject.type === "table" && schemaObject.name === "orders" && i === 0 && !base_table) {
						base_table = schemaObject;
					} else if (schemaObject.type === "table" && schemaObject.name.startsWith("orders")) {
						secondTableHeight += schemaObject.height;
					}
				}
			}

			let delta = 0;
			let base_table_y = 0;
			let base_table_height = 0;
			if (base_table) {
				// const headStyles = base_table.headStyles;
				// const estimatedHeadHeight =
				// 	headStyles.fontSize * 0.3 * (headStyles.lineHeight * 0.3) +
				// 	headStyles.padding.top +
				// 	headStyles.padding.bottom +
				// 	(headStyles.borderWidth.top || 0) +
				// 	(headStyles.borderWidth.bottom || 0);
				base_table_y = base_table.position.y;
				base_table_height = base_table.height;
				const base_row_count = JSON.parse(base_table.content).length + 1;
				const row_height = base_table_height / base_row_count;
				const actual_row_count = Array.isArray(documentObject.products) ? documentObject.products.length : 0;
				const actual_table_height = row_height * (actual_row_count + 1);
				delta = actual_table_height - base_table_height;
				base_table.height = actual_table_height;
			}

			let tableCount = 0;
			for (let i = 0; i < populatedTemplate.schemas.length; i++) {
				let schemaArrays = populatedTemplate.schemas[i];
				for (let j = 0; j < schemaArrays.length; j++) {
					let schemaObject = schemaArrays[j];
					if (i > 0) {
						schemaObject.position.y =
							schemaObject.position.y + i * PAGE_HEIGHT - secondTableHeight - this.marginTop * (i + 1);
					}

					if (base_table && schemaObject.position && schemaObject.position.y > base_table_y + base_table_height) {
						schemaObject.position.y += delta;
					}

					if (schemaObject.type === "table" && schemaObject.name.startsWith("orders")) {
						tableCount++;
						if (tableCount === 1) {
							schemaObjects.push(schemaObject);
						}
					} else {
						schemaObjects.push(schemaObject);
					}
				}
			}

			if (!base_table) {
				return populatedTemplate;
			}
			schemaObjects.find((field) => field.type === "table" && field.name === "orders").height = base_table.height;

			this.fillFieldContent(schemaObjects);

			populatedTemplate.schemas = [schemaObjects];

			populatedTemplate = this.handlePageOverflow(populatedTemplate, PAGE_HEIGHT);
			populatedTemplate = this.translateStaticLabels(populatedTemplate);
			return populatedTemplate;
		},
		handlePageOverflow(populatedTemplate, pageHeight = 297 - this.marginBottom) {
			for (let pageIndex = 0; pageIndex < populatedTemplate.schemas.length; pageIndex++) {
				const page = populatedTemplate.schemas[pageIndex];
				if (!page) continue;

				const tables = page.filter((field) => field.type === "table" && field.name.startsWith("orders"));
				for (const table of tables) {
					const fieldTop = table.position.y;
					const fieldBottom = fieldTop + table.height;

					if (fieldBottom > pageHeight) {
						const tableData = JSON.parse(table.content);
						const rowHeight = table.height / (tableData.length + 1);
						const availableHeight = pageHeight - fieldTop;
						const rowsFitInPage = Math.floor(availableHeight / rowHeight) - 1;

						if (rowsFitInPage < 1) continue;

						const currentPageRows = tableData.slice(0, rowsFitInPage);
						const nextPageRows = tableData.slice(rowsFitInPage);

						table.content = JSON.stringify(currentPageRows);
						table.height = rowHeight * (currentPageRows.length + 1);

						if (!populatedTemplate.schemas[pageIndex + 1]) {
							populatedTemplate.schemas[pageIndex + 1] = [];
						}

						if (nextPageRows.length > 0) {
							const nextPageTable = {
								...table,
								name: `orders${pageIndex + 1}`,
								position: { x: table.position.x, y: this.marginTop },
								content: JSON.stringify(nextPageRows),
								height: rowHeight * (nextPageRows.length + 1),
							};

							populatedTemplate.schemas[pageIndex + 1].push(nextPageTable);

							const elementsBelow = page.filter(
								(field) => !field.name.startsWith("orders") && field.position.y > fieldBottom
							);

							const heightDifference = nextPageTable.height + this.marginTop;

							elementsBelow.forEach((field) => {
								const newField = { ...field };
								newField.position = {
									x: field.position.x,
									y: heightDifference + (field.position.y - fieldBottom),
								};
								populatedTemplate.schemas[pageIndex + 1].push(newField);
								const idx = page.indexOf(field);
								if (idx !== -1) page.splice(idx, 1);
							});
						}
					}
				}

				const nonTableFields = page.filter((field) => field.type !== "table");
				for (const field of nonTableFields) {
					const fieldBottom = field.position.y + field.height;
					if (fieldBottom > pageHeight) {
						if (!populatedTemplate.schemas[pageIndex + 1]) {
							populatedTemplate.schemas[pageIndex + 1] = [];
						}

						const startingY = field.position.y;
						const fieldsToMove = page.filter((f) => f.type !== "table" && f.position.y >= startingY);

						for (const f of fieldsToMove) {
							const idx = page.indexOf(f);
							if (idx !== -1) page.splice(idx, 1);
						}

						for (const f of fieldsToMove) {
							const relativeY = f.position.y - startingY;
							f.position.y = this.marginTop + relativeY;
							populatedTemplate.schemas[pageIndex + 1].push(f);
						}
					}
				}
			}
			return populatedTemplate;
		},
		fillFieldContent(currentArray) {
			const currentItem = this.documentObject;
			const fieldMappings = {
				companyName:
					this.documentType === "proforma"
						? currentItem.sender.name
						: this.documentType === "shipment"
						? currentItem.sender.company?.name
						: this.getTranslation("company_name"),
				companyAddress:
					[this.company.address, this.company.phoneNumber, this.company.email, this.company.website]
						.filter((part) => part)
						.join(" - ") || this.getTranslation("company_address"),
				customer_company_name:
					this.documentType === "proforma"
						? currentItem.receiver.name
						: this.documentType === "shipment"
						? currentItem.receiver.company?.name
						: this.getTranslation("customer_company_name"),
				customer_company_address:
					currentItem.receiver.companyAddress || this.getTranslation("customer_company_address"),
				date: new Date(currentItem.date).toLocaleDateString(this.selectedLanguage) || this.getTranslation("date"),
				invoiceNo:
					this.documentType === "shipment"
						? currentItem.bookingCode
						: this.documentType === "proforma"
						? currentItem.no
						: this.getTranslation("invoice_no"),
				shipmentMethod:
					this.documentType === "proforma"
						? this.getShipmentMethod(currentItem.shipmentMethod)
						: this.documentType === "shipment"
						? this.getShipmentMethod(currentItem.method)
						: this.getTranslation("shipment_method"),
				shippingTerm: currentItem.shippingTerm.name || this.getTranslation("shipping_term"),
				loading:
					`${currentItem.loadingPort !== undefined ? currentItem.loadingPort?.name : currentItem?.loadingPoint ?? ""}${
						currentItem.loadingCountry && currentItem.loadingCountry.name
							? `, ${this.getCountryName(currentItem.loadingCountry.name)}`
							: ""
					}` || this.getTranslation("loading_point"),

				unloading:
					`${
						currentItem.unloadingPort !== undefined
							? currentItem.unloadingPort?.name
							: currentItem?.unloadingPoint ?? ""
					}${
						currentItem.unloadingCountry && currentItem.unloadingCountry.name
							? `, ${this.getCountryName(currentItem.unloadingCountry.name)}`
							: ""
					}` || this.getTranslation("unloading_point"),
			};

			const staticLabels = {
				shipmentMethod: "shipment_method",
				invoiceNo: "invoice_no",
				date: "date",
				shippingTerm: "shipping_term",
				loading: "loading_point",
				unloading: "unloading_point",
			};

			currentArray.forEach((field) => {
				if (field.type === "text" && field.name in fieldMappings) {
					const value = fieldMappings[field.name];
					if (field.name in staticLabels) {
						const labelKey = staticLabels[field.name];
						const label = this.getTranslation(labelKey);
						field.content = `${label}: ${value}`;
					} else {
						field.content = `${value}`;
					}
				}

				if (field.type === "table" && field.name === "orders") {
					const lang = this.selectedLanguage;
					if (!Array.isArray(currentItem.products)) {
						field.content = JSON.stringify([]);
					} else {
						const tableData = currentItem.products.map((product) => [
							(product.name && product.name[lang]) || product.name?._default,
							`${product.quantity}${product.unitCode || ""}`,
							parseFloat(product.amount || 0).toLocaleString(this.$lang, {
								style: "currency",
								currency: currentItem.currency.code,
							}),
							((parseFloat(product.quantity) || 0) * (parseFloat(product.amount) || 0)).toLocaleString(this.$lang, {
								style: "currency",
								currency: currentItem.currency.code,
							}),
						]);
						field.content = JSON.stringify(tableData);
						const total = Array.isArray(currentItem.products)
							? currentItem.products.reduce((sum, product) => sum + (product.quantity || 0) * (product.amount || 0), 0)
							: 0;
						currentArray.forEach((f) => {
							if (f.type === "text" && f.name === "total") {
								f.content = total.toLocaleString(this.$lang, {
									style: "currency",
									currency: currentItem.currency.code,
								});
							}
						});
					}
				}
			});
			return currentArray;
		},

		getCountryName(countryName) {
			return this.getTranslation(`$country.${countryName}`);
		},
		getShipmentMethod(shipmentMethod) {
			return this.getTranslation(`$shipment.$method.${shipmentMethod}`);
		},
		loadTemplate() {
			if (!this.selectedTemplate) {
				console.warn("No template selected – skipping loadTemplate()");
				this.hideProgressBar();
				return;
			}
			const iframe = this.$refs.reactIframe;
			if (iframe && iframe.contentWindow) {
				let populatedTemplate;
				try {
					populatedTemplate = this.populateTemplate(this.selectedTemplate, this.documentObject);
				} catch (error) {
					console.error("Error populating template:", error);
					this.hideProgressBar();
					return;
				}

				iframe.contentWindow.postMessage({ type: "LOAD_TEMPLATE", data: populatedTemplate }, ALLOWED_ORIGIN);
			} else {
				console.error("Iframe not found");
			}
		},

		onSavePdfDialogSaved({ action, docId, fileName, attach, pdfName }) {
			if (action === "save-as-new") {
				this.pendingDocumentFilename = fileName;
				this.pendingAttachmentFilename = attach ? pdfName : null;
			} else {
				this.pendingAttachmentFilename = attach ? pdfName : null;
			}
			this.pendingAttachToDoc = attach;

			const iframe = this.$refs.reactIframe;
			if (!iframe || !iframe.contentWindow) {
				console.error("Iframe not found");
				return;
			}

			if (action === "override") {
				this.isOverrideDocument = true;
				this.overrideDocumentId = docId;

				iframe.contentWindow.postMessage({ type: "SAVE_TEMPLATE" }, ALLOWED_ORIGIN);

				if (attach) {
					iframe.contentWindow.postMessage({ type: "REQUEST_PDF_DATA" }, ALLOWED_ORIGIN);
				}
			} else {
				this.pendingSaveTemplate = true;

				iframe.contentWindow.postMessage({ type: "SAVE_TEMPLATE" }, ALLOWED_ORIGIN);

				if (attach) {
					iframe.contentWindow.postMessage({ type: "REQUEST_PDF_DATA" }, ALLOWED_ORIGIN);
				}
			}
		},
		handlePdfData(pdfBase64) {
			if (!this.pendingAttachToDoc) return;

			const byteString = window.atob(pdfBase64);
			const arrayBuffer = new ArrayBuffer(byteString.length);
			const uint8Array = new Uint8Array(arrayBuffer);
			for (let i = 0; i < byteString.length; i++) {
				uint8Array[i] = byteString.charCodeAt(i);
			}
			const blob = new Blob([arrayBuffer], { type: "application/pdf" });

			const attachmentName = this.pendingAttachmentFilename || "document";
			const file = new File([blob], attachmentName + ".pdf", { type: "application/pdf" });

			remote.files.send(file, this.corporation.id, {
				onSuccess: (uploadedFile) => {
					const newAttachment = {
						name: attachmentName,
						note: "Auto-attached PDF",
						file: uploadedFile,
					};
					this.attachFileToDocument(newAttachment);
				},
				onFail: (err) => {
					console.error("File upload failed:", err);
				},
			});

			this.pendingAttachmentFilename = null;
			this.pendingAttachToDoc = false;
		},

		attachFileToDocument(newAttachment) {
			const docId = this.documentObject.id;
			if (this.documentType === "proforma") {
				remote.proformaInvoices.attachments.create(newAttachment, docId, {
					onSuccess: () => {
						this.$emit("change");
						this.onDialogClosed();
					},
					onFail: (err) => {
						console.error("Failed to create proforma attachment:", err);
					},
				});
			} else if (this.documentType === "shipment") {
				const modifiedAttachment = {
					...newAttachment,
					ownerId: this.corporation.id,
					accessModifier: AccessModifier.Public,
				};
				remote.shipments.attachments.create(modifiedAttachment, docId, {
					onSuccess: () => {
						this.$emit("change");
						this.onDialogClosed();
					},
					onFail: (err) => {
						console.error("Failed to create shipment attachment:", err);
					},
				});
			}
		},

		addDocument(docCopy, { onSuccess, onFail }) {
			if (this.documentType === "proforma") {
				remote.proformaInvoices.update(docCopy, { onSuccess, onFail });
			} else if (this.documentType === "shipment") {
				remote.shipments.update(docCopy, { onSuccess, onFail });
			}
		},

		deleteSelectedTemplate() {
			if (!this.selectedTemplate) {
				this.showSnackBar({ message: "No template selected", color: "warning" });
				return;
			}

			this.showConfirmDialog({
				message: this.$t("delete_confirm_message"),
				onConfirm: () => {
					const docId = this.documentObject.id;
					const origin = this.selectedTemplate.origin;

					if (origin === "document") {
						if (this.documentType === "proforma") {
							remote.proformaInvoices.deletePdfTemplate(docId, this.selectedTemplate._id, {
								onSuccess: () => this.$emit("change"),
								onFail: (error) => console.error("Error deleting proforma template:", error),
							});
						} else if (this.documentType === "shipment") {
							remote.shipments.deletePdfTemplate(docId, this.selectedTemplate._id, {
								onSuccess: () => this.$emit("change"),
								onFail: (error) => console.error("Error deleting shipment template:", error),
							});
						}
					}
					if (origin === "corporation") {
						remote.corporations.deletePdfTemplate(this.corporation.id, this.selectedTemplate._id, {
							onSuccess: () => this.fetchAllPdfTemplates(),
							onFail: (error) => console.error("Error deleting corporation template:", error),
						});
					}
				},
			});
		},

		openAddTemplateDialog() {
			this.isAddTemplateDialogVisible = true;
		},

		onAddTemplateSaved(payload) {
			if (payload.action === "save-as-new") {
				this.pendingAddTemplateName = payload.name;
				this.isSavingTemplate = true;
				this.saveTemplate();
			} else if (payload.action === "override") {
				this.isOverrideTemplate = true;
				this.overridePdfTemplate(payload.id);
			}
		},
		getPartnerDetails() {
			const partner =
				this.documentType === "shipment"
					? this.documentObject.receiver.company
					: this.documentType === "proforma"
					? this.documentObject.receiver
					: null;
			const corporationId = this.corporation.id;
			if (partner.isAbstract) {
				remote.partners.find(corporationId, partner.referenceId, {
					onSuccess: (partnerDetails) => {
						this.documentObject.receiver.companyAddress = partnerDetails.address;
					},
					onFail: (error) => {
						console.error("Error fetching partner details:", error);
					},
				});
			} else {
				remote.partners.findByCorporationId(corporationId, partner.referenceId, {
					onSuccess: (partnerDetails) => {
						this.documentObject.receiver.companyAddress = partnerDetails.address;
					},
					onFail: (error) => {
						console.error("Error fetching partner details:", error);
					},
				});
			}
		},
	},
	watch: {
		documentTemplates: {
			immediate: true,
			handler(newVal) {
				if (newVal && newVal.length > 0) {
					this.selectedCategory = true;
				} else {
					this.selectedCategory = false;
				}
			},
		},
		selectedCategory(newVal) {
			const availableTemplates = this.filteredTemplates;
			if (!availableTemplates.some((t) => t._id === this.selectedTemplateId)) {
				this.selectedTemplateId = availableTemplates.length ? availableTemplates[0]._id : null;
			}
		},
		isAddTemplateDialogVisible(newVal) {
			if (newVal) {
			}
		},
		value(newVal) {
			if (newVal) {
				this.getPartnerDetails();
				this.showProgressBar();
				this.fetchAllPdfTemplates();

				this.pendingPdfFilename = null;
				this.pendingAttachToDoc = false;
				this.pendingSaveTemplate = false;

				this.isOverrideDocument = false;
				this.overrideDocumentId = null;
			}
		},
		documentObject: {
			immediate: true,
			deep: true,
			handler(newVal) {
				if (this.value) {
					this.fetchAllPdfTemplates();
				}
			},
		},
		selectedTemplate(newVal) {
			if (newVal && this.isIframeReady) {
				this.showProgressBar();
				this.loadTemplate();
			}
		},
		async selectedLanguage(newVal, oldVal) {
			if (newVal !== oldVal) {
				await this.loadTranslations();
				if (this.isIframeReady) {
					this.showProgressBar();
					this.loadTemplate();
				}
			}
		},
	},
	activated() {
		this.pendingAddTemplateName = null;
		this.pendingPdfFilename = null;
		this.pendingAttachToDoc = false;
		this.isSavingTemplate = false;
		this.isOverrideTemplate = false;
		this.pendingSaveTemplate = false;
		this.isOverrideDocument = false;
		this.overrideDocumentId = null;
	},
	async created() {
		await this.loadTranslations();
	},
	mounted() {
		window.addEventListener("message", this.handleMessage);
	},
	beforeUnmount() {
		window.removeEventListener("message", this.handleMessage);
	},
};
</script>

<style scoped>
.full-iframe {
	width: 100%;
	height: calc(100vh - 160px);
	border: none;
	overflow: hidden;
}
</style>
