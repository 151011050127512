<template>
	<page-template>
		<template slot="content" v-if="shipment">
			<v-container fluid>
				<v-row class="d-flex align-center justify-space-between flex-wrap px-4" no-gutters>
					<v-col cols="auto">
						<v-btn text outlined @click="backAction">
							<v-icon left>icon-angle-left</v-icon>
							{{ $t("shipments") }}
						</v-btn>
					</v-col>

					<v-col v-if="iAmSenderCompany" cols="auto" class="d-flex align-center justify-end flex-wrap">
						<v-btn outlined text @click="createPdf" class="mx-1">
							<v-icon>icon-document</v-icon>
							<span class="ml-1 v-typography--body-sm"> {{ $t("create_document") }} </span>
						</v-btn>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" lg="7">
						<booking-details
							:shipment="shipment"
							:i-am-sender-company="iAmSenderCompany"
							:i-am-receiver-company="iAmReceiverCompany"
							:i-am-sender-customs-agency="iAmSenderCustomsAgency"
							:i-am-receiver-customs-agency="iAmReceiverCustomsAgency"
							:i-am-owner="iAmOwner"
							:is-archived="isArchived"
							@update:shipment="handleUpdateShipment"
							@send-email="sendEmailDialog.show"
						/>

						<attachments
							:shipment="shipment"
							:my-role="myRole"
							:i-am-sender-company="iAmSenderCompany"
							:i-am-receiver-company="iAmReceiverCompany"
							:i-am-sender-customs-agency="iAmSenderCustomsAgency"
							:i-am-receiver-customs-agency="iAmReceiverCustomsAgency"
							:corporation="corporation"
							@show-alert-dialog="showAlertDialog"
							@show-snackbar="showSnackBar"
						></attachments>

						<activities v-model="shipment" v-if="!isMobile" />
					</v-col>

					<v-col cols="12" lg="5">
						<products
							v-if="shipment.containers.length"
							:shipment="shipment"
							@merged-products-updated="handleMergedProducts"
							@show-container="showContainerDetail"
						/>
						<containers
							ref="containersComponent"
							:shipment="shipment"
							:is-land-shipment="isLandShipment"
							:customs-agency="customsAgency"
							:i-am-owner="iAmOwner"
							@reload-shipment="loadShipment(shipment.id)"
							@show-alert-dialog="showAlertDialog"
							@show-snackbar="showSnackBar"
						/>

						<activities v-model="shipment" v-if="isMobile" />
					</v-col>
				</v-row>

				<send-email-dialog
					v-model="sendEmailDialog.visibility"
					:customerPartner="customerPartner"
					:shipmentId="shipment.id"
					:shipment="shipment"
					@closed="sendEmailDialog.hide"
				/>
				<pdf-dialog
					v-model="pdfDialog.visible"
					document-type="shipment"
					:document-object="shipment"
					@change="refreshShipment"
				/>
			</v-container>
		</template>
	</page-template>
</template>

<script>
import remote from "../../../data/remote";
import uiMixin from "../../../store/interfaces/ui.mixin";
import PageTemplate from "../../templates/PageTemplate";
import { mapState, mapGetters } from "vuex";
import SendEmailDialog from "./dialog/SendEmailDialog";
import Activities from "./partial/Activities.vue";
import { iconHelper as iconHelperMixin } from "../../mixins/utils.mixin";
import Attachments from "./partial/Attachments.vue";
import BookingDetails from "./partial/BookingDetails.vue";
import Containers from "./partial/Containers.vue";
import Products from "./partial/Products.vue";
import PdfDialog from "../proforma_invoice/dialogs/PdfDialog.vue";

export default {
	components: {
		PageTemplate,
		SendEmailDialog,
		Activities,
		BookingDetails,
		Containers,
		Attachments,
		Products,
		PdfDialog,
	},
	mixins: [uiMixin, iconHelperMixin],
	data() {
		return {
			pdfDialog: {
				visible: false,
			},
			shipment: null,
			mergedProducts: [],
			deleting: false,
			saving: false,
			sendEmailDialog: {
				visibility: false,
				show: () => {
					this.sendEmailDialog.visibility = true;
				},
				hide: () => {
					this.sendEmailDialog.visibility = false;
				},
			},
		};
	},
	computed: {
		...mapState(["countries", "corporation"]),
		...mapGetters(["company", "customsAgency"]),
		isLandShipment() {
			return this.shipment.method === "land";
		},
		isMobile() {
			return this.$vuetify.breakpoint.mdAndDown;
		},
		iAmSenderCompany() {
			return this.shipment && this.company ? this.company.id === this.shipment.sender.company.referenceId : false;
		},
		iAmReceiverCompany() {
			return this.shipment && this.company ? this.company.id === this.shipment.receiver.company.referenceId : false;
		},
		iAmOwner() {
			if (this.iAmSenderCompany) {
				return this.shipment.sender.isOwner;
			} else if (this.iAmReceiverCompany) {
				return this.shipment.receiver.isOwner;
			}
			if (this.shipment && this.shipment.sender && this.shipment.receiver && this.company) {
				const isSenderOwner = this.shipment.sender.isOwner;
				const isReceiverOwner = this.shipment.receiver.isOwner;
				return isSenderOwner || isReceiverOwner;
			}
			return false;
		},
		iAmSenderCustomsAgency() {
			return this.shipment && this.customsAgency && this.shipment.sender.customsAgency
				? this.customsAgency.id === this.shipment.sender.customsAgency.customsAgencyId
				: false;
		},
		iAmReceiverCustomsAgency() {
			return this.shipment && this.customsAgency && this.shipment.receiver.customsAgency
				? this.customsAgency.id === this.shipment.receiver.customsAgency.customsAgencyId
				: false;
		},
		iAmAnySender() {
			return this.iAmSenderCompany || this.iAmSenderCustomsAgency;
		},
		iAmAnyReceiver() {
			return this.iAmReceiverCompany || this.iAmReceiverCustomsAgency;
		},
		isArchived() {
			if (this.iAmAnySender) return this.shipment.sender.accessibility == "archived";
			else if (this.iAmAnyReceiver) return this.shipment.receiver.accessibility == "archived";
		},
		totalAmount() {
			let result = 0;
			if (this.shipment.containers.length)
				for (const container of this.shipment.containers) {
					result += container.totalAmount ? container.totalAmount : 0;
				}
			return result;
		},
		myRole() {
			if (this.iAmSenderCompany) return "sender";
			if (this.iAmReceiverCompany) return "receiver";
			if (this.iAmSenderCustomsAgency) return "sender_customs_agency";
			if (this.iAmReceiverCustomsAgency) return "receiver_customs_agency";
		},
		customerPartner() {
			if (this.iAmSenderCompany) return this.shipment.receiver.company;
			else return this.shipment.sender.company;
		},
	},
	watch: {
		shipment(newValue) {
			// this.handleShipment(newValue);
			this.handleShipment();
		},
	},
	methods: {
		handleMergedProducts(products) {
			this.shipment.products = products;
		},
		refreshShipment() {
			this.loadShipment(this.shipment.id);
		},
		createPdf() {
			this.pdfDialog.visible = true;
		},
		showContainerDetail(container) {
			this.$refs.containersComponent.containerDetailDialog.show(container);
		},
		handleUpdateShipment(updatedShipment) {
			this.shipment = updatedShipment;
		},
		loadShipment(id) {
			this.showProgressBar();
			remote.shipments.find(id, {
				onSuccess: (result) => {
					// this.handleShipment(result);
					this.shipment = result;
					this.hideProgressBar();
				},
				onFail: (error) => {
					if (error) console.error(error);
				},
			});
		},
		loadContainers() {
			remote.shipments.containers.filter(this.shipment.id, {
				onSuccess: (result) => (this.shipment.containers = result),
			});
		},
		containerTotalAmount(container) {
			let result = undefined;
			if ((container.products.length || container.expenses.length) && result == null) result = 0;
			for (const product of container.products) result += product.quantity * product.amount;
			for (const expense of container.expenses) result += expense.quantity * expense.amount;
			return result;
		},
		handleShipment() {
			// this.shipment = item;
			let loadingCountry;
			if (this.shipment.loadingCountryId) {
				loadingCountry = this.countries.find((c) => c.id === this.shipment.loadingCountryId);
			} else if (this.shipment.loadingPort) {
				loadingCountry = this.countries.find((c) => c.ports.find((p) => p.code === this.shipment.loadingPort.code));
			}
			this.shipment.loadingCountry = {
				id: loadingCountry ? loadingCountry.id : undefined,
				name: loadingCountry ? loadingCountry.name : undefined,
				code: loadingCountry ? loadingCountry.code : undefined,
			};

			let unloadingCountry;
			if (this.shipment.unloadingCountryId)
				unloadingCountry = this.countries.find((c) => c.id === this.shipment.unloadingCountryId);
			else if (this.shipment.unloadingPort)
				unloadingCountry = this.countries.find((c) => c.ports.find((p) => p.code === this.shipment.unloadingPort.code));
			this.shipment.unloadingCountry = {
				id: unloadingCountry ? unloadingCountry.id : undefined,
				name: unloadingCountry ? unloadingCountry.name : undefined,
				code: unloadingCountry ? unloadingCountry.code : undefined,
			};

			if (this.shipment.containers.length)
				this.shipment.containers.forEach((c) => (c.totalAmount = this.containerTotalAmount(c)));
		},
		deleteAction() {
			this.showConfirmDialog({
				message: this.$t("delete_confirm_message"),
				onConfirm: () => {
					this.deleting = true;
					remote.shipments.delete(this.shipment.id, {
						onSuccess: (_) => {
							this.deleting = false;
							this.showSnackBar({
								message: this.$t("$message.deleted", {
									item: this.$t("shipment"),
								}),
							});
							if (this.$route.fullPath != this.$router.history._startLocation) this.$router.go(-1);
							else this.$router.replace({ name: "shipments" });
						},
					});
				},
			});
		},

		backAction() {
			this.$router.go(-1);
		},
	},
	created() {
		this.loadShipment(this.$route.params.id);
	},
};
</script>
