<template>
	<page-template>
		<template slot="content">
			<common-table
				:title="$t('shipments')"
				:showFilter="true"
				:filtered="filterDialog.item.role || filterDialog.item.accessibilities.length ? true : false"
				:options.sync="tableOptions"
				:headers="headers"
				:items="shipments"
				:items-length="pagination.totalItemCount"
				:items-per-page="tableOptions.itemsPerPage"
				:items-per-page-options="tableFooterProps.itemsPerPageOptions"
				:loading="loading"
				:entity-name="$t('shipment')"
				:button-text="!customsAgency ? $t('add_item', { item: $t('shipment') }) : null"
				@row-click="shipmentAction"
				@button-click="newShipment"
				@filter-button-click="filterDialog.show"
			>
				<template v-slot:item.method="{ item }">
					<v-tooltip bottom open-delay="500">
						<template v-slot:activator="{ on }">
							<v-icon v-on="on">
								{{ getShipmentMethodIcon(item.method) }}
							</v-icon>
						</template>
						<span>{{ $t(`$shipment.$method.${item.method}`) }}</span>
					</v-tooltip>
				</template>
				<template v-slot:item.date="{ item }">
					{{ item.date ? item.date.format($t("$format.date")) : "" }}
				</template>

				<template v-slot:item.fromTo="{ item }">
					{{ item.loadingPort ? item.loadingPort.name : item.loadingPoint }}
					<v-icon size="16">icon-arrow-right</v-icon>
					{{ item.unloadingPort ? item.unloadingPort.name : item.unloadingPoint }}
				</template>
			</common-table>

			<shipment-filter-dialog v-model="filterDialog.visibility" :item="filterDialog.item" @done="filterDialog.done" />

			<new-shipment-dialog v-model="newDialog.visibility" @saved="newDialog.saved" />
		</template>
	</page-template>
</template>

<script>
import uiMixin from "../../../store/interfaces/ui.mixin";
import { pagination as paginationMixin, iconHelper as iconHelperMixin } from "../../mixins/utils.mixin";
import PageTemplate from "../../templates/PageTemplate";
import remote from "../../../data/remote/index";
import NewShipmentDialog from "./dialog/NewShipmentDialog.vue";
import ShipmentFilterDialog from "./dialog/ShipmentFilterDialog.vue";
import { mapGetters } from "vuex";
import CommonTable from "../../partials/CommonTable.vue";

export default {
	mixins: [uiMixin, paginationMixin, iconHelperMixin],
	components: {
		PageTemplate,
		NewShipmentDialog,
		ShipmentFilterDialog,
		CommonTable,
	},
	data() {
		return {
			shipments: [],
			loading: true,
			filterDialog: {
				visibility: false,
				item: {
					role: null, // sender, receiver
					accessibilities: [], // present, archived
					bookingCode: "",
					method: null, // Aerial, Land, Railway, Sea
				},
				show: () => (this.filterDialog.visibility = true),
				hide: () => (this.filterDialog.visibility = false),
				done: (item) => {
					this.filterDialog.item = item;
					this.tableOptions.page = 1;
					this.$router.push({
						query: {
							role: this.filterDialog.item.role || undefined,
							accessibilities: this.filterDialog.item.accessibilities.length
								? this.filterDialog.item.accessibilities.join(",")
								: undefined,
							bookingCode: this.filterDialog.item.bookingCode ? this.filterDialog.item.bookingCode.trim() : undefined,
							method: this.filterDialog.item.method,
						},
					});
					this.filterDialog.hide();
					this.loadItems();
				},
			},
			headers: [
				{ value: "method", width: 1 },
				{ text: this.$t("sender"), value: "sender.company.name" },
				{ text: this.$t("receiver"), value: "receiver.company.name" },
				{ text: this.$t("date"), value: "date" },
				{ value: "fromTo" },
				{ text: this.$t("total_amount"), value: "totalAmount" },
			],
			newDialog: {
				visibility: false,
				myRole: null,
				show: (role) => {
					this.newDialog.myRole = role;
					this.newDialog.visibility = true;
				},
				hide: () => {
					this.newDialog.myRole = null;
					this.newDialog.visibility = false;
				},
				saved: (item) => {
					this.newDialog.hide();
					this.shipmentAction(item);
				},
			},
		};
	},
	computed: {
		...mapGetters(["customsAgency"]),
		filterIcon() {
			return this.filterDialog.item.role || this.filterDialog.item.accessibilities.length
				? "icon-filter"
				: "icon-filter-outline";
		},
	},
	methods: {
		calculateTotalAmount(shipment) {
			let result = null;
			if (shipment.containers && shipment.containers.length) {
				for (const container of shipment.containers) {
					if ((container.products && container.products.length) || (container.expenses && container.expenses.length)) {
						if (result === null) result = 0;
						for (const product of container.products) {
							result += product.quantity * product.amount;
						}
						for (const expense of container.expenses) {
							result += expense.quantity * expense.amount;
						}
					}
				}
			}
			return result;
		},
		loadItems() {
			this.loading = true;
			remote.shipments.filter({
				filter: {
					role: this.filterDialog.item.role,
					accessibilities: this.filterDialog.item.accessibilities,
					bookingCode: this.filterDialog.item.bookingCode,
					method: this.filterDialog.item.method,
				},
				pager: {
					number: this.tableOptions.page,
					size: this.tableOptions.itemsPerPage,
				},
				sorter: this.tableOptions.sortBy.length
					? {
							property: this.tableOptions.sortBy,
							method: this.tableOptions.sortDesc ? "desc" : "asc",
					  }
					: undefined,
				onSuccess: (result) => {
					this.pagination = result.pagination;
					this.shipments = result.items
						.sort((a, b) => {
							if (a.createdAt && b.createdAt) return a.createdAt < b.createdAt ? 1 : -1;
							else return !a.createdAt ? 1 : -1;
						})
						.map((shipment) => {
							const amount = this.calculateTotalAmount(shipment);
							if (amount !== null) {
								shipment.totalAmount = amount.toLocaleString(this.$lang, {
									style: "currency",
									currency: shipment.currency.code,
								});
							} else {
								shipment.totalAmount = "-";
							}
							return shipment;
						});
					this.loading = false;
				},
				onFail: () => (this.loading = false),
			});
		},
		shipmentAction(item) {
			this.$router.push({ name: "shipment", params: { id: item.id } });
		},
		newShipment() {
			if (!this.customsAgency) {
				this.newDialog.show();
			}
		},
	},
	created() {
		this.$trackPageView(this.$route.path);
		this.filterDialog.item.role = this.$route.query.role;
		const accessibilities = this.$route.query.accessibilities;
		if (accessibilities) this.filterDialog.item.accessibilities = accessibilities.split(",");
		else this.filterDialog.item.accessibilities = ["present"];
		this.filterDialog.item.bookingCode = this.$route.query.bookingCode;
		this.filterDialog.item.method = this.$route.query.method;
		this.loadItems();
	},
};
</script>
