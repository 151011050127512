export default {
	$accessibility: {
		archived: "已归档",
		present: "当前",
	},
	$accessModifier: {
		$description: {
			hidden_from_receiver: "对接收方隐藏",
			hidden_from_sender: "对发送方隐藏",
			private: "私人",
			shared_with_custom: "自定义共享",
			shared_with_everyone: "与所有人共享",
			shared_with_receiver: "与接收方共享",
			shared_with_sender: "与发送方共享",
		},
	},
	$antProduct: {
		limitGBStorage: "{limit}GB 存储",
		limitMBStorage: "{limit}MB 存储",
		limitUser: "{limit} 用户",
		limitUsers: "{limit} 用户",
		renewAtPricePerUnit: "将在 {currency}{price} 每 {unit} 续订",
		unlimitedModule: "无限 {module}",
		upToLimitModule: "最多 {limit} {module}",
	},
	$company: {
		address: "Kotekli Mah. Denizli Yolu Bul. Teknopark Sitesi B Blok No:4B Ic kapi No:14 MENTESE / MUGLA, 土耳其",
		googleMapSrc:
			"https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12715.523446137679!2d28.3731307!3d37.1793022!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14bf732687fd3eb1%3A0x2815d6eefcce8910!2sMu%C4%9Fla%20Teknopark!5e0!3m2!1szh-CN!2str!4v1701805110461!5m2!1szh-CN!2str",
		name: "Slush 软件公司",
		phoneNumber: "+90 (555) 444-33-22",
		slogan: "通过 Antrego 加速您的业务。以数字化、安全且轻松的方式管理您的运输流程。",
	},
	$country: {
		afghanistan: "阿富汗",
		aland: "奥兰群岛",
		albania: "阿尔巴尼亚",
		algeria: "阿尔及利亚",
		american_samoa: "美属萨摩亚",
		andorra: "安道尔",
		angola: "安哥拉",
		anguilla: "安圭拉",
		antarctica: "南极洲",
		antigua_and_barbuda: "安提瓜和巴布达",
		argentina: "阿根廷",
		armenia: "亚美尼亚",
		aruba: "阿鲁巴",
		australia: "澳大利亚",
		austria: "奥地利",
		azerbaijan: "阿塞拜疆",
		bahamas: "巴哈马",
		bahrain: "巴林",
		bangladesh: "孟加拉国",
		barbados: "巴巴多斯",
		belarus: "白俄罗斯",
		belgium: "比利时",
		belize: "伯利兹",
		benin: "贝宁",
		bermuda: "百慕大",
		bhutan: "不丹",
		bolivia: "玻利维亚",
		bonaire_sint_eustatius_and_saba: "博内尔、圣尤斯特歇斯和萨巴",
		bosnia_and_herzegovina: "波斯尼亚和黑塞哥维那",
		botswana: "博茨瓦纳",
		bouvet_island: "布韦岛",
		brazil: "巴西",
		british_indian_ocean_territory: "英属印度洋领地",
		british_virgin_islands: "英属维尔京群岛",
		brunei: "文莱",
		bulgaria: "保加利亚",
		burkina_faso: "布基纳法索",
		burundi: "布隆迪",
		cabo_verde: "佛得角",
		cambodia: "柬埔寨",
		cameroon: "喀麦隆",
		canada: "加拿大",
		cayman_islands: "开曼群岛",
		central_african_republic: "中非共和国",
		chad: "乍得",
		chile: "智利",
		china: "中国",
		christmas_island: "圣诞岛",
		cocos_islands: "科科斯（基林）群岛",
		colombia: "哥伦比亚",
		comoros: "科摩罗",
		congo_republic: "刚果共和国",
		cook_islands: "库克群岛",
		costa_rica: "哥斯达黎加",
		croatia: "克罗地亚",
		cuba: "古巴",
		curacao: "库拉索",
		cyprus: "塞浦路斯",
		czechia: "捷克",
		denmark: "丹麦",
		djibouti: "吉布提",
		dominica: "多米尼克",
		dominican_republic: "多米尼加共和国",
		dr_congo: "刚果民主共和国",
		ecuador: "厄瓜多尔",
		egypt: "埃及",
		el_salvador: "萨尔瓦多",
		equatorial_guinea: "赤道几内亚",
		eritrea: "厄立特里亚",
		estonia: "爱沙尼亚",
		eswatini: "斯威士兰",
		ethiopia: "埃塞俄比亚",
		falkland_islands: "福克兰群岛",
		faroe_islands: "法罗群岛",
		fiji: "斐济",
		finland: "芬兰",
		france: "法国",
		french_guiana: "法属圭亚那",
		french_polynesia: "法属波利尼西亚",
		french_southern_territories: "法属南部领地",
		gabon: "加蓬",
		gambia: "冈比亚",
		georgia: "格鲁吉亚",
		germany: "德国",
		ghana: "加纳",
		gibraltar: "直布罗陀",
		greece: "希腊",
		greenland: "格陵兰",
		grenada: "格林纳达",
		guadeloupe: "瓜德罗普",
		guam: "关岛",
		guatemala: "危地马拉",
		guernsey: "根西岛",
		guinea: "几内亚",
		guinea_bissau: "几内亚比绍",
		guyana: "圭亚那",
		haiti: "海地",
		heard_island_and_mcdonald_islands: "赫德岛和麦克唐纳群岛",
		honduras: "洪都拉斯",
		hong_kong: "香港",
		hungary: "匈牙利",
		iceland: "冰岛",
		india: "印度",
		indonesia: "印度尼西亚",
		iran: "伊朗",
		iraq: "伊拉克",
		ireland: "爱尔兰",
		isle_of_man: "马恩岛",
		israel: "以色列",
		italy: "意大利",
		ivory_coast: "科特迪瓦",
		jamaica: "牙买加",
		japan: "日本",
		jersey: "泽西岛",
		jordan: "约旦",
		kazakhstan: "哈萨克斯坦",
		kenya: "肯尼亚",
		kiribati: "基里巴斯",
		kosovo: "科索沃",
		kuwait: "科威特",
		kyrgyzstan: "吉尔吉斯斯坦",
		laos: "老挝",
		latvia: "拉脱维亚",
		lebanon: "黎巴嫩",
		lesotho: "莱索托",
		liberia: "利比里亚",
		libya: "利比亚",
		liechtenstein: "列支敦士登",
		lithuania: "立陶宛",
		luxembourg: "卢森堡",
		macao: "澳门",
		madagascar: "马达加斯加",
		malawi: "马拉维",
		malaysia: "马来西亚",
		maldives: "马尔代夫",
		mali: "马里",
		malta: "马耳他",
		marshall_islands: "马绍尔群岛",
		martinique: "马提尼克",
		mauritania: "毛里塔尼亚",
		mauritius: "毛里求斯",
		mayotte: "马约特",
		mexico: "墨西哥",
		micronesia: "密克罗尼西亚",
		moldova: "摩尔多瓦",
		monaco: "摩纳哥",
		mongolia: "蒙古",
		montenegro: "黑山",
		montserrat: "蒙特塞拉特",
		morocco: "摩洛哥",
		mozambique: "莫桑比克",
		myanmar: "缅甸",
		namibia: "纳米比亚",
		nauru: "瑙鲁",
		nepal: "尼泊尔",
		netherlands: "荷兰",
		new_caledonia: "新喀里多尼亚",
		new_zealand: "新西兰",
		nicaragua: "尼加拉瓜",
		niger: "尼日尔",
		nigeria: "尼日利亚",
		niue: "纽埃",
		norfolk_island: "诺福克岛",
		north_korea: "朝鲜",
		north_macedonia: "北马其顿",
		northern_mariana_islands: "北马里亚纳群岛",
		norway: "挪威",
		oman: "阿曼",
		pakistan: "巴基斯坦",
		palau: "帕劳",
		palestine: "巴勒斯坦",
		panama: "巴拿马",
		papua_new_guinea: "巴布亚新几内亚",
		paraguay: "巴拉圭",
		peru: "秘鲁",
		philippines: "菲律宾",
		pitcairn_islands: "皮特凯恩群岛",
		poland: "波兰",
		portugal: "葡萄牙",
		puerto_rico: "波多黎各",
		qatar: "卡塔尔",
		reunion: "留尼汪",
		romania: "罗马尼亚",
		russia: "俄罗斯",
		rwanda: "卢旺达",
		saint_barthelemy: "圣巴泰勒米",
		saint_helena: "圣赫勒拿",
		saint_lucia: "圣卢西亚",
		saint_martin: "圣马丁",
		saint_pierre_and_miquelon: "圣皮埃尔和密克隆",
		samoa: "萨摩亚",
		san_marino: "圣马力诺",
		sao_tome_and_principe: "圣多美和普林西比",
		saudi_arabia: "沙特阿拉伯",
		senegal: "塞内加尔",
		serbia: "塞尔维亚",
		seychelles: "塞舌尔",
		sierra_leone: "塞拉利昂",
		singapore: "新加坡",
		sint_maarten: "荷属圣马丁",
		slovakia: "斯洛伐克",
		slovenia: "斯洛文尼亚",
		solomon_islands: "所罗门群岛",
		somalia: "索马里",
		south_africa: "南非",
		south_georgia_and_south_sandwich_islands: "南乔治亚和南桑威奇群岛",
		south_korea: "韩国",
		south_sudan: "南苏丹",
		spain: "西班牙",
		sri_lanka: "斯里兰卡",
		st_kitts_and_nevis: "圣基茨和尼维斯",
		st_vincent_and_grenadines: "圣文森特和格林纳丁斯",
		sudan: "苏丹",
		suriname: "苏里南",
		svalbard_and_jan_mayen: "斯瓦尔巴和扬马延",
		sweden: "瑞典",
		switzerland: "瑞士",
		syria: "叙利亚",
		taiwan: "台湾",
		tajikistan: "塔吉克斯坦",
		tanzania: "坦桑尼亚",
		thailand: "泰国",
		timor_leste: "东帝汶",
		togo: "多哥",
		tokelau: "托克劳",
		tonga: "汤加",
		trinidad_and_tobago: "特立尼达和多巴哥",
		tunisia: "突尼斯",
		turkiye: "土耳其",
		turkmenistan: "土库曼斯坦",
		turks_and_caicos_islands: "特克斯和凯科斯群岛",
		tuvalu: "图瓦卢",
		uganda: "乌干达",
		ukraine: "乌克兰",
		united_arab_emirates: "阿拉伯联合酋长国",
		united_kingdom: "英国",
		united_states: "美国",
		uruguay: "乌拉圭",
		us_minor_outlying_islands: "美国本土外小岛屿",
		us_virgin_islands: "美属维尔京群岛",
		uzbekistan: "乌兹别克斯坦",
		vanuatu: "瓦努阿图",
		vatican_city: "梵蒂冈",
		venezuela: "委内瑞拉",
		vietnam: "越南",
		wallis_and_futuna: "瓦利斯和富图纳",
		western_sahara: "西撒哈拉",
		yemen: "也门",
		zambia: "赞比亚",
		zimbabwe: "津巴布韦",
	},
	$currency: {
		euro: "欧元",
		turkish_lira: "土耳其里拉",
		us_dollar: "美元",
	},
	$employeeType: {
		admin: "管理员",
		default: "默认",
		exporter: "出口商",
		importer: "进口商",
	},
	$format: {
		date: "YYYY/MM/DD",
		date_time: "YYYY/MM/DD HH:mm:ss",
		date_time_without_second: "YYYY/MM/DD HH:mm",
	},
	$home: {
		$noCompany: {
			create_company_subtitle: "在此创建您的公司。",
			create_company_title: "您想添加公司吗？",
			description: "您已成功登录。现在您需要加入公司或创建新公司。",
			join_company_subtitle: "在此输入您的代码并加入公司。",
			join_company_title: "您想加入现有公司吗？",
			title: "欢迎 {name}",
		},
		$unauthorized: {
			description: "使用 Antrego 控制您的进出口流程",
			forget_password_subtitle: "请输入您的电子邮件以重置密码。",
			sign_in_subtitle: "在这里登录",
			sign_in_title: "您已经有账号了吗？",
			sign_up_subtitle: "在这里注册",
			sign_up_title: "您想创建账号吗？",
			title: "欢迎来到 Antrego",
		},
	},
	$invitationType: {
		company_partner: "商业合作邀请",
		customs_partner: "海关合作邀请",
		employee: "员工邀请",
	},
	$landing: {
		$benefits: {
			benefits_title: "好处",
			business_process: "加快业务流程。",
			control_title: "使用 Antrego 控制。",
			highlight_word: "控制",
			risk_of_error: "降低错误风险。",
			text: "Antrego 是一款货运跟踪程序，帮助航运公司、客户及海关经纪人通过共享平台跟踪货运流程。",
			time_and_cost_savings: "节省时间和成本。",
		},
		$content: {
			$text: {
				0: "在一个地方跟踪您的所有货运，并通过动态报告控制您的财务。",
				1: "与您的客户分享您的实时更新，以提高服务质量。",
				2: "在一个系统中查看并分享所有与货运相关的文件。",
				3: "通过所有设备和地点访问 Antrego，轻松控制流程。",
				4: "使用 SHA-256 加密技术存储所有数据。安心无忧。",
				5: "与所有业务流程中的利益相关者联合跟踪。更高效地管理流程。",
			},
			$title: {
				0: "货运管理",
				1: "客户关系",
				2: "文件管理",
				3: "轻松访问",
				4: "数据安全",
				5: "联合跟踪",
			},
		},
		$home: {
			text: "选择我们，享受无缝的数字化跟踪和共同控制。",
			title: "轻松数字化管理所有运输流程",
		},
		$lifecycle: {
			headline: "流程",
			info_button: "获取信息",
			lifecycle_texts: {
				0: "上传附加文件以创建您的形式发票并立即与客户分享。",
				1: "输入您的货运详情并添加文件。通知您的客户，通过单一渠道共同管理所有货运流程。",
				2: "实时跟踪与进出口相关的财务状况。",
			},
			lifecycle_titles: {
				0: "登录并创建形式发票",
				1: "创建货运",
				2: "跟踪财务活动",
			},
			productivity_title: "通过三步提高生产力并减少复杂性和错误。",
			tracking_guide_title: "简单跟踪指南",
		},
		$logistics: {
			logistics_title: "支持的物流类型",
			solutions_title: "适合每种货物的正确解决方案",
			text: "与 Antrego 一起，作为您在所有运输方式中的数字化合作伙伴，我们将在全球所有运输过程中支持您。",
		},
		$pdfEditor: {
			title: "创建发票如此简单！",
			longTitle: "使用 PDF 编辑器快速定制发票。",
			description:
				"我们的 PDF 编辑器是一款强大的工具，可使创建和编辑发票更加实用。节省时间，并向您的商业伙伴提供专业外观的文档。",
			features: {
				design: "发票设计：添加您的徽标，调整颜色，并自定义发票格式。",
				edit: "文档编辑：快速编辑 PDF，添加或删除文本。",
				shareSave: "共享和保存：下载、共享或直接在平台上保存您创建的文档。",
			},
			note: "此设置使用户可以直观地探索功能，并以书面形式查看优点。",
		},
		$process: {
			digital_future: "告别传统方法，迈向数字化未来",
			digital_shipping: "使用 Antrego 的数字化货运跟踪",
			digital_shipping_texts: {
				0: "为接收订单创建的形式发票通过单一渠道轻松创建和跟踪。",
				1: "形式发票发送后，客户会立即收到通知，并通过 Antrego 批准。",
				2: "通过 Antrego 管理文件流程。文件不会丢失，不会发生延迟，错误的可能性也减少到最低。",
				3: "供应商、客户和海关经纪人可以即时查看货运详情并联合跟踪。",
				4: "财务报告每月和每年自动生成。",
			},
			question_title: "Antrego 如何改变您的生活？",
			tracking_method: "数字化您的跟踪方法，加快您的流程。",
			traditional_shipping: "传统货运跟踪",
			traditional_shipping_texts: {
				0: "订单通过电子邮件、信息或电话等不同渠道收集，导致内部跟踪困难。",
				1: "使用 Excel 等工具创建形式发票，批准流程通过电子邮件、WhatsApp 或电话手动处理。",
				2: "手动文件流程导致错误和延迟，造成时间和财务损失。",
				3: "供应商、客户和海关经纪人没有一个共同的平台来跟踪货运详情。",
				4: "每次货运后单独检查财务数据，使报告变得困难。",
			},
		},
		$question: {
			$text: {
				0: "<p>这是一款货运跟踪程序，帮助公司及其客户通过联合跟踪，避免因跟踪阶段延误而造成的损失。</p>",
				1: "<p>- 实时分享您的所有更新给客户。提高您的服务质量。<br>\n      - 在一个系统中查看并分享所有与货运相关的文件。<br>\n      - 从一个地方跟踪您的所有货运。通过动态报告控制您的财务。</p>",
				2: "<p>- 海运<br>\n      - 空运<br>\n      - 陆运<br>\n      - 铁路运输</p>",
				3: "<p>通过其服务，它随时为您提供帮助，使您的货运过程更轻松。<br>\n      <strong>1. 轻松访问</strong><br>\n      通过网络浏览器可从所有设备和地点访问。<br>\n      <strong>2. 数据安全</strong><br>\n      所有数据都使用 SHA-256 加密技术存储。<br>\n      <strong>3. 联合跟踪</strong><br>\n      它为流程中的所有利益相关者提供跟踪机会。</strong>",
				4: "<p>是的，它是一款付费应用。通过数字化您的所有货运流程，我们希望使您的工作更轻松，并为您持续进行有用的开发。为了确保这些改进的可持续性和您使用的服务器空间，我们收取月费。</p>",
				5: "<p>不。您的客户无需支付任何费用来跟踪您进行的货运。</p>",
			},
			$title: {
				0: "什么是 Antrego？",
				1: "Antrego 在运输过程中会为我的公司带来什么贡献？",
				2: "Antrego 支持哪些物流类型？",
				3: "我为什么要使用 Antrego？",
				4: "Antrego 是付费应用吗？",
				5: "我的客户需要为 Antrego 支付费用吗？",
			},
			save_percentage: "每月节省 16%",
			text: "轻松访问 Antrego 上的所有信息，如有其他问题，请随时联系我们。",
			title: "快速了解您感兴趣的内容",
		},
		cancel_anytime: "随时取消",
		customize_package: "您可以购买额外的存储空间和用户，并根据需要自定义您的套餐",
		free_features_1: "10 个货运",
		free_features_2: "100 MB 存储",
		free_features_3: "无限商业伙伴",
		free_features_4: "1 位用户",
		free_plan: "免费",
		free_price: "$0",
		free_regeneration: "",
		free_title: "每月",
		premium_annual_features_1: "20 位用户",
		premium_annual_features_2: "100 个伙伴",
		premium_annual_features_3: "1000 个货运",
		premium_annual_features_4: "1000 个发票",
		premium_annual_price: "$83.25",
		premium_annual_regeneration: "每年以 $999 续订",
		premium_annual_title: "每年",
		premium_monthly_features_1: "无限货运",
		premium_monthly_features_2: "无限商业伙伴",
		premium_monthly_features_3: "2GB 存储",
		premium_monthly_features_4: "3 位用户",
		premium_monthly_price: "$99",
		premium_monthly_regeneration: "每月以 $99 续订",
		premium_monthly_title: "每月",
		premium_plan: "高级计划",
		premium_plus_annual_features_1: "无限货运",
		premium_plus_annual_features_2: "无限商业伙伴",
		premium_plus_annual_features_3: "5GB 存储",
		premium_plus_annual_features_4: "5 位用户",
		premium_plus_annual_price: "$83.25",
		premium_plus_annual_regeneration: "每年以 $999 续订",
		premium_plus_annual_title: "每年",
		premium_plus_monthly_features_1: "无限货运",
		premium_plus_monthly_features_2: "无限商业伙伴",
		premium_plus_monthly_features_3: "5GB 存储",
		premium_plus_monthly_features_4: "5 位用户",
		premium_plus_monthly_price: "$249",
		premium_plus_monthly_regeneration: "每月以 $249 续订",
		premium_plus_monthly_title: "每月",
		premium_plus_plan: "高级 Plus",
		pricing_text: "我们可以在不超出您的预算的情况下，以最好的方式满足您的需求。",
		pricing_title: "Antrego 保证为您提供最适合的套餐和价格。",
		save_percentage: "每月节省 16%",
	},
	$language: {
		ar: "العربية",
		de: "Deutsch",
		en: "English",
		es: "Español",
		fr: "Français",
		hi: "हिन्दी",
		ind: "Bahasa Indonesia",
		it: "Italiano",
		ja: "日本語",
		ko: "한국어",
		pt: "Português",
		ru: "Русский",
		tr: "Türkçe",
		vi: "Tiếng Việt",
		zh: "中文",
	},
	$languageLocal: {
		ar: "阿拉伯语",
		de: "德语",
		en: "英语",
		es: "西班牙语",
		fr: "法语",
		hi: "印地语",
		ind: "印度尼西亚语",
		it: "意大利语",
		ja: "日语",
		ko: "韩语",
		pt: "葡萄牙语",
		ru: "俄语",
		tr: "土耳其语",
		vi: "越南语",
		zh: "中文",
	},
	$message: {
		cannot_delete_verified_partner: "您无法删除已验证的合作伙伴。",
		confirm: "您确定要 {function} 吗？",
		created: "{item} 已创建。",
		deleted: "{item} 已删除。",
		error_on_view_file: "查看文件时出错。",
		file_oversize: "文件大小最多为 {size}。",
		invalid: "{item} 无效",
		migrated: "{item} 已迁移。({value})",
		process_has_been_started: "您的 {item} 已开始。",
		sent: "{item} 已发送。",
		sent_email: "电子邮件已发送。 ({value})",
		sign_in_failed: "电子邮件或密码不正确",
		unsupported_file_type: "不支持的文件类型",
		updated: "{item} 已更新。",
	},
	$pageTitles: {
		accept_invitation: "接受邀请",
		company_profile: "公司简介",
		home: "首页",
		landing_page: "无缝数字追踪，共享控制",
		partners: "合作伙伴",
		pdf_editor: "PDF 编辑器",
		product_groups: "产品组",
		products: "产品",
		proforma_invoices: "形式发票",
		shipment_details: "货运详情",
		shipments: "货运",
		sign_in: "登录",
		sign_up: "注册",
		subscription: "订阅",
		user_profile: "用户资料",
	},
	$partnershipStatusTooltip: {
		createdHereDescription: "未注册的合作伙伴，您在我们的平台上创建了他们的档案，因此您可以查看和编辑他们的信息。",
		createdHereTitle: "在此创建：",
		description: "您可以跟踪与合作伙伴的关系状态。以下是差异：",
		verifiedPartnersDescription: "这些合作伙伴已接受您的邀请并在平台上注册。他们的信息仅供查看，无法编辑。",
		verifiedPartnersTitle: "已验证的合作伙伴：",
	},
	$productGroup: {
		add_child: "添加子项",
		add_new_product_group: "添加新产品组",
		add_to_root: "添加到根",
		has_children_fail_message: "包含子产品组的产品组无法删除",
		initial_message: "使用此 {0} 按钮创建您的第一个产品组。",
		name: "产品组名称",
		number_of_product: "产品数量",
	},
	$proformaInvoice: {
		$actionDescription: {
			approve: "{name} 已批准。",
			create: "{name} 创建。",
			create_attachment: "{name} 创建了附件。",
			delete_attachment: "{name} 删除了附件。",
			demand_revise: "{name} 要求修订。",
			mark_as_processed: "{name} 标记为已处理。",
			reject: "{name} 已拒绝。",
			revoke: "{name} 已撤销。",
			send: "{name} 发送给接收方评估。",
			update: "{name} 更新。",
			update_attachment: "{name} 更新了附件。",
		},
		$actions: {
			approve: "批准",
			demand_revise: "要求修订",
			mark_as_approved: "已批准",
			mark_as_processed: "已处理",
			mark_as_rejected: "已拒绝",
			mark_as_revise_demanded: "要求修订",
			mark_as_revoked: "已撤销",
			mark_as_sent: "已发送",
			reject: "拒绝",
			revoke: "撤销",
			send: "发送",
		},
		$status: {
			approved: "已批准",
			pending_approval: "待批准",
			pending_revise: "待修订",
			processed: "已处理",
			rejected: "已拒绝",
			revoked: "已撤销",
			template: "模板",
		},
		approve_confirm_message: "您确定要批准形式发票吗？",
		approve_success_message: "形式发票已批准。",
		demand_revise_description_message: "请详细说明您的修订期望：",
		demand_revise_success_message: "形式发票已退回给发送方修订。",
		mark_as_processed_confirm_message: "您确认要标记形式发票为已处理吗？",
		mark_as_processed_success_message: "形式发票已标记为已处理。",
		no_attachment_text: "尚未添加附件。",
		reject_confirm_message: "您确定要拒绝形式发票吗？",
		reject_success_message: "形式发票已拒绝。",
		revoke_confirm_message: "您确定要撤销形式发票吗？",
		revoke_success_message: "形式发票已撤销。",
		send_confirm_message: "您确认要将形式发票发送给接收方吗？",
		send_success_message: "形式发票已发送。",
	},
	$serviceMessage: {
		already_subs_module: "此模块已订阅。",
		bad_request: "错误的请求",
		code_already_used: "代码已被使用。",
		container_status_not_available: "容器状态不可用。",
		existing_email_warning: "电子邮件已存在。",
		existing_employee_error: "此用户已添加到您的公司。",
		existing_employee_for_email_warning: "邀请代码已发送至此电子邮件",
		existing_invitation_warning: "邀请代码已发送至此电子邮件。",
		existing_token_already_have_time: "现有令牌已经有足够的时间使用。",
		existing_user: "用户已存在。",
		five_min_reset_password_mail: "在请求重置密码电子邮件后，您必须至少等待 5 分钟。",
		forbidden: "禁止",
		have_no_corporation: "您没有公司",
		have_no_custom_agency: "您没有海关代理",
		have_no_employee: "您没有员工",
		instance_can_no_delete_existing_booking_code: "由于现有的预订代码，实例无法删除。",
		instance_cannot_delete: "无法删除实例。",
		instance_not_found: "未找到实例。",
		internal_server_error: "内部服务器错误",
		invalid_current_password: "当前密码无效。",
		invalid_identifier_or_password: "标识符或密码无效",
		invalid_invitation_code: "邀请代码不正确。",
		invalid_moving_request: "移动请求无效",
		invalid_operation: "操作无效",
		invalid_refresh_token: "刷新令牌无效。",
		invalid_unit: "单位无效",
		invitation_accepting_is_due_to_the_admin: "接受邀请取决于管理员。",
		invitation_already_discarded: "邀请已被丢弃。",
		invitation_already_used: "邀请已被使用。",
		invitation_has_been_used: "邀请已被使用。",
		invitation_not_exist_email: "邀请不存在此电子邮件地址。",
		invitation_not_for_company: "此邀请不适用于公司。",
		invitation_not_for_custom_agency: "此邀请不适用于海关代理。",
		invitation_not_found: "未找到邀请。",
		invitation_not_valid: "此邀请已无效。",
		link_has_been_expired: "链接已过期。",
		link_not_be_used_anymore: "此链接可能不再可用",
		missing_required_fields: "缺少必填字段。",
		no_employee_found: "未找到员工",
		no_proper_partner_found: "未找到合适的合作伙伴。",
		not_acceptable: "不可接受",
		not_allowed_action: "您无权进行此操作。",
		not_allowed_use_api: "您无权使用此 API。",
		not_found: "未找到",
		not_have_purchase_subscription: "您的订阅计划已达到定义的最大限制。如需添加更多内容，请考虑升级您的订阅计划。",
		not_permitted_use_api: "您无权使用此 API。",
		own_invitation_cannot_accept: "您不能接受自己的邀请。",
		parent_group_not_found: "未找到父组",
		partnerId_required: "需要合作伙伴 ID。",
		partnership_already_exists: "合作伙伴已添加。",
		password_cant_involve_space_char: "密码不能包含空格。",
		password_min_chars_least: "密码必须至少包含 6 个字符。",
		payment_required: "请为此流程订阅。",
		paytr_integration_error: "PayTR 集成错误",
		ports_are_required: "需要端口。",
		product_group_tree_not_found: "未找到产品组树",
		proforma_invoice_action_not_available: "形式发票的状态不适用于此操作。",
		proforma_invoice_template_update: "形式发票必须是模板才能更新。",
		shipment_email_not_exist: "请输入有效的电子邮件。",
		shipment_method_is_required: "需要运输方式。",
		shipping_term_is_required: "需要运输条款。",
		someone_else_uses_this_email: "其他人正在使用此电子邮件地址。",
		someone_else_uses_this_username: "其他人正在使用此用户名。",
		something_went_wrong: "出了点问题",
		type_can_be_abstract_company: '"类型"属性只能是"抽象公司"',
		unauthorized: "未经授权",
		undefined_result: "未定义的结果",
		unexpected_error_occurred: "发生意外错误",
		unit_already_added: "单位已添加。",
		user_already_employee: "用户已经是员工。",
		user_already_exists: "用户已在公司中存在。",
		user_does_not_exist: "用户不存在。",
		user_not_found: "未找到用户。",
		username_min_chars_error: '"用户名"必须至少包含 6 个字符。',
	},
	$shipment: {
		$actionDescription: {
			$land: {
				create_container: "{name} 添加了车辆。",
				delete_container: "{name} 删除了车辆。",
				update_container: "{name} 更新了车辆。",
			},
			create: "{name} 创建。",
			create_attachment: "{name} 添加了附件。",
			create_container: "{name} 添加了容器。",
			delete_attachment: "{name} 删除了附件。",
			delete_container: "{name} 删除了容器。",
			update: "{name} 更新。",
			update_attachment: "{name} 更新了附件。",
			update_container: "{name} 更新了容器。",
		},
		$containerStatus: {
			arrived: "已到达",
			off_load: "卸货",
			on_load: "装载中",
			on_way: "在路上",
			reserved: "已预订",
		},
		$method: {
			aerial: "空运",
			land: "陆运",
			railway: "铁路",
			sea: "海运",
		},
		$vehicle: {
			license_number: "车牌号",
		},
		archiving_success_message: "货运已归档。",
		leave_message: "留下您的消息或评论",
		no_booking_info: "尚未设置预订信息。使用 {0} 按钮添加。",
		no_transportation_info: "尚未设置详细信息。使用 {0} 按钮设置。",
		un_archiving_success_message: "货运已取消归档。",
	},
	$shortening: {
		number: "编号",
	},
	$socialMedia: {
		facebook: "脸书",
		instagram: "Instagram",
		linked_in: "领英",
		twitter: "推特",
	},
	$subscription: {
		$message: {
			check_your_payment_method: "您的订阅无法续订。请检查您的付款方式。",
			fail: "发生意外错误。请稍后再试。如果问题仍然存在，请通过发送电子邮件至 info@antrego.net 报告。",
			ok: "购买过程已经开始。该过程的结果将通过电子邮件通知您。当支付成功完成时，您的订阅将开始。",
			payment_process_is_ongoing:
				"您的付款流程正在进行中。该过程的结果将通过电子邮件通知您。当支付成功完成时，您的订阅将开始。",
			success: "您的订阅已成功创建。您可以轻松开始使用 Antrego。",
			unsubscribed: "已取消订阅",
			you_dont_have_any_subscription: "您没有任何订阅。请在订阅页面上选择一个套餐并轻松开始使用。",
			you_dont_have_any_subscription_text: "请选择一个套餐以继续。",
			you_dont_have_any_subscription_title: "您没有任何订阅。",
		},
	},
	$unit: {
		acres: "英亩",
		boxes: "箱",
		cubic_meter: "立方米",
		dozen: "打",
		feet: "英尺",
		fluid_ounces: "液体盎司",
		gallons: "加仑",
		gram: "克",
		hectare: "公顷",
		inches: "英寸",
		kilogram: "千克",
		kilometer: "千米",
		liter: "升",
		meter: "米",
		miles: "英里",
		milligram: "毫克",
		milliliter: "毫升",
		millimeter: "毫米",
		ounces: "盎司",
		pallets: "托盘",
		pieces: "件",
		pounds: "磅",
		quarts: "夸脱",
		square_feet: "平方英尺",
		square_inches: "平方英寸",
		square_kilometer: "平方千米",
		square_meter: "平方米",
		square_miles: "平方英里",
		square_yards: "平方码",
		ton: "吨",
		yards: "码",
	},
	$validation: {
		email: "请输入有效的电子邮件",
		invalid: "{item} 无效",
		max_length: "{item} 必须少于 {length} 个字符",
		min_length: "{item} 必须多于 {length} 个字符",
		must_length: "{item} 必须为 {length} 个字符",
		only_number: "{item} 应为数字",
		password_repeat_warning: "密码重复与新密码不一致。",
		required: "{item} 是必需的",
		required_for_default_language: "{item} 对于公司默认语言是必需的。",
		you_must_approve_distance_sales_agreement: "您必须批准远程销售合同",
	},
	about: "关于我们",
	accept_invitation: "接受邀请",
	accept_invitation_success_message: "您已成功使用邀请。",
	accessibility: "无障碍",
	actions: "操作",
	active: "活跃",
	activities: "活动",
	add: "添加",
	add_item: "添加 {item}",
	add_new_employee: "添加新员工",
	add_new_product: "添加新产品",
	add_template: "添加模板",
	address: "地址",
	again: "再次",
	alert: "警告",
	already_have_employee_message: "您已经是公司的员工。",
	amount: "数量",
	approve: "批准",
	archive: "归档",
	attachment: "附件",
	attachments: "附件",
	bank: "银行",
	benefits: "好处",
	billed_to: "账单寄至",
	booking: "预订",
	booking_code: "预订代码",
	booking_details: "预订详情",
	business_partner: "商业伙伴",
	business_partners: "商业伙伴",
	cancel: "取消",
	change_password: "更改密码",
	checking_invitation_code_message: "正在检查邀请代码",
	choose_image: "选择图片",
	choose_your_plan: "选择您的套餐",
	clear: "清除",
	click_to_play_the_video: "点击播放视频",
	close: "关闭",
	code: "代码",
	commercial: "商业",
	commercial_partner: "商业伙伴",
	commercial_partners: "商业伙伴",
	companies: "公司",
	company: "公司",
	company_address: "公司地址",
	company_name: "公司名称",
	company_profile: "公司简介",
	configuration: "配置",
	confirm: "确认",
	confirm_distance_selling_agreement: "确认远程销售合同。",
	consult_us: "咨询我们",
	contact: "联系方式",
	contact_info: "联系信息",
	contact_text: "请放心，我们会尽快联系您。",
	contact_title: "咨询我们，简化您的流程",
	contact_to_us: "联系我们",
	container: "容器",
	container_no: "集装箱号",
	containers: "容器",
	content: "内容",
	contract: "合同",
	copy_link: "复制链接",
	countries: "国家",
	country: "国家",
	create: "创建",
	create_a_new_account: "创建新账户",
	create_company: "创建公司",
	create_document: "创建文档",
	created_at: "创建于",
	created_here: "在此创建",
	credit_card_info: "信用卡信息",
	credit_card_no: "信用卡号",
	currencies: "货币",
	currency: "货币",
	current_password: "当前密码",
	current_plan: "当前套餐",
	customer_company_address: "客户公司地址",
	customer_company_name: "客户公司名称",
	customs: "海关",
	customs_agencies: "海关代理",
	customs_agency: "海关代理",
	cvv: "CVV",
	daily: "每日",
	dashboard: "仪表板",
	date: "日期",
	day: "天",
	default: "默认",
	default_language: "默认语言",
	delete: "删除",
	delete_account: "删除账户",
	delete_confirm_message: "您确认删除吗？",
	delete_document: "删除文档",
	delete_template: "删除模板",
	description: "描述",
	detail: "详情",
	discard: "丢弃",
	distance_sales_agreement: "远程销售合同",
	document: "文档",
	document_actions: "文档操作",
	document_name: "文档名称",
	document_number: "文件号",
	document_overwrite: "将编辑保存为新文档",
	document_save_info: "此文档将仅保存到此形式发票中。",
	download: "下载",
	download_pdf: "下载 PDF",
	edit: "编辑",
	edit_document: "编辑文档",
	edit_item: "编辑 {item}",
	edit_product: "编辑产品",
	edit_profile: "编辑资料",
	edit_template: "编辑模板",
	edit_your_profile_picture: "编辑个人资料照片",
	email: "电子邮件",
	employee: "员工",
	employee_type: "员工类型",
	employees: "员工",
	empty_data_warning: "该区域尚无数据。您可以开始使用示例数据探索平台。",
	estimated_arrival_day: "预计到达日",
	expense: "费用",
	expenses: "费用",
	expires_at: "到期于",
	exported_containers: "出口的容器",
	fail: "失败",
	failed: "失败",
	faq: "常见问题",
	feedback: "反馈",
	file: "文件",
	file_name: "文件名",
	file_preview: "文件预览",
	file_view: "查看文件",
	filter: "过滤",
	first_name: "名字",
	first_name_last_name: "名字 姓氏",
	forget_password: "忘记密码",
	forget_password_message: "您忘记了密码吗？",
	forget_password_subtitle: "请输入您的电子邮件地址以重置密码。",
	free: "免费",
	from: "从",
	get_started_now: "立即开始",
	give_us_feedback: "给我们反馈",
	go_to_panel: "前往面板",
	hide: "隐藏",
	home_page: "首页",
	i_have_invitation_code: "我有邀请码",
	imported_containers: "进口的容器",
	in_progress: "正在进行中",
	incoming: "正在到来",
	incoming_shipments: "即将到来的货运",
	initial: "初始",
	invitation: "邀请",
	invitation_code: "邀请代码",
	invitation_mail_sent: "邀请电子邮件已发送",
	invitations: "邀请",
	invoice_no: "发票号码",
	join: "加入",
	join_company: "加入公司",
	language: "语言",
	language_options: "语言选项",
	last_name: "姓氏",
	last_one_year: "过去一年",
	latitude: "纬度",
	left_a_comment: "留下评论",
	link_copied: "链接已复制",
	link_copy_failed: "链接复制失败",
	loading_country: "装货国家",
	loading_point: "装货点",
	loading_port: "装货港",
	log_in: "登录",
	logo: "徽标",
	longitude: "经度",
	mail_sent: "电子邮件已发送",
	manage_profile: "管理资料",
	message: "消息",
	message_company_create: "公司已创建。",
	message_company_join: "请输入邀请码。",
	message_company_joined: "您已加入公司",
	message_employee_delete: "员工已删除。",
	message_employee_save: "员工已保存。",
	message_join_company: "输入您的代码以加入现有公司",
	method: "方法",
	migrate: "迁移",
	migrate_partner: "迁移公司",
	mobile_number: "手机号码",
	month: "月",
	month_lowercase: "月",
	monthly: "每月",
	my_profile: "我的资料",
	my_role: "我的角色",
	name: "姓名",
	new: "新建",
	new_company_package: "免费",
	new_password: "新密码",
	new_shipment: "新货运",
	next: "下一步",
	no: "否",
	no_content: "无内容",
	no_entity: "无实体",
	no_entity_message: "未找到实体。",
	no_text: "无文本",
	not_found_page_message: "抱歉，我找不到此页面 :(",
	note: "备注",
	notes: "备注",
	ok: "确认",
	operation_fail: "操作失败",
	outgoing: "外发",
	outgoing_shipments: "外发货运",
	packages: "套餐",
	parent_group: "父组",
	partner: "伙伴",
	partners: "伙伴",
	partnership_status: "合作状态",
	passive: "被动",
	password: "密码",
	pay: "支付",
	payback: "退款",
	payback_confirm_message: "您确认退款吗？",
	payback_warning_message: "如果继续，您的订阅将被终止。",
	payment: "支付",
	payment_amount: "支付金额",
	payment_awaited: "等待支付",
	payment_history: "支付历史",
	payment_info: "付款信息",
	payment_information: "付款信息",
	payment_success: "支付成功",
	payment_summary: "支付摘要",
	payment_will_be_made_3d_secure: "*您的付款将通过 3D Secure 进行。",
	pdf_editor: "PDF编辑器",
	pdf_name: "PDF名称",
	pending_invitations: "待处理的邀请",
	per_month: "每月",
	phone_number: "电话号码",
	premium: "高级",
	premium_plus: "高级 Plus",
	previous: "上一步",
	pricing: "定价",
	process: "过程",
	product: "产品",
	product_group: "产品组",
	product_group_name: "产品组名称",
	product_groups: "产品组",
	product_name: "产品名称",
	products: "产品",
	profile: "个人资料",
	proforma_invoice: "形式发票",
	proforma_invoices: "形式发票",
	proforma_number: "形式发票号",
	purchase: "购买",
	quantity: "数量",
	receiver: "接收方",
	receiver_company: "接收公司",
	receiver_customs_agency: "接收方海关代理",
	redirection_to_home_link: "主页",
	redirection_to_home_message: "您还可以转到 ",
	redirection_to_sign_in_message: "您已经有账户了吗？",
	redirection_to_sign_up_message: "还没有账户吗？",
	reload: "重新加载",
	renewal: "续订",
	repaid: "已还款",
	reset_password: "重置密码",
	rows_per_page: "每页行数",
	sales: "销售",
	save: "保存",
	save_as_document: "保存为文档",
	save_as_new_document: "保存为新文档",
	save_as_new_template: "保存为新模板",
	save_as_template: "保存为模板",
	save_changes: "保存更改",
	save_pdf_to_attachments: "保存 PDF 到附件",
	saved_card_info: "保存的卡信息",
	search: "搜索",
	select: "选择",
	select_document_to_overwrite: "选择要覆盖的文档",
	select_file: "选择文件",
	select_file_place_holder: "请选择图片",
	select_language: "选择语言",
	select_template_to_overwrite: "选择要覆盖的模板",
	selected_partner: "已选合作伙伴",
	send: "发送",
	send_again: "再次发送",
	send_email: "发送电子邮件",
	send_feedback: "发送反馈",
	send_invitation: "发送邀请",
	sender: "发送方",
	sender_company: "发送公司",
	sender_customs_agency: "发送方海关代理",
	settings: "设置",
	shipment: "货运",
	shipment_method: "货运方式",
	shipment_type: "货运类型",
	shipments: "货运",
	shipments_filter: "货运过滤器",
	shipping_term: "运输条款",
	shipping_terms: "运输条款",
	show: "显示",
	sign_in: "登录",
	sign_out: "退出",
	sign_out_confirm_message: "您确认要退出吗？",
	sign_out_success_message: "再见。",
	sign_up: "注册",
	social_media: "社交媒体",
	social_media_account: "社交媒体账户",
	start_time: "开始时间",
	status: "状态",
	subscribe: "订阅",
	subscription: "订阅",
	subscription_info_message: "在订阅期间，您可以无限制地享受所有服务。",
	subscription_warning_message: "**您的卡信息将被安全存储，并且您的订阅将定期续订，除非您取消。",
	success: "成功",
	super: "超级",
	supported_files_img: "支持的文件：JPG、PNG。",
	switch_language: "切换语言",
	switch_theme: "切换主题",
	target: "目标",
	target_partner: "目标合作伙伴",
	tax_number: "税号",
	template: "模板",
	template_actions: "模板操作",
	template_name: "模板名称",
	template_overwrite: "将编辑保存为新模板",
	template_save_info: "保存的模板稍后可用于形式发票和货运。",
	terms_of_sale_and_other_comments: "销售条款 - 评论",
	theme: "主题",
	this_month: "本月",
	this_year: "今年",
	title: "标题",
	to: "到",
	toDelete: "删除",
	total: "总计",
	total_amount: "总金额",
	transaction_no: "交易编号",
	transactions: "交易",
	try_for_free: "免费试用",
	type: "类型",
	types: "类型",
	un_archive: "取消归档",
	unit: "单位",
	units: "单位",
	unloading_country: "卸货国家",
	unloading_point: "卸货点",
	unloading_port: "卸货港",
	unsubscribe: "取消订阅",
	unsubscribe_confirm_message: "如果您取消订阅，您将无法有效使用系统功能。您确定要取消订阅吗？",
	upload_new_image: "上传新图片",
	use_code: "使用代码",
	use_invitation_code: "使用邀请码",
	use_invitation_code_description: "输入发送到您邮箱的邀请码。",
	use_invitation_code_success_message: "邀请码已成功使用。",
	user_profile: "我的资料",
	vehicle: "车辆",
	vehicles: "车辆",
	verified_partner: "已验证合作伙伴",
	verify_password: "验证密码",
	visibility: "可见性",
	warehouse: "仓库",
	warehouses: "仓库",
	website: "网站",
	week: "周",
	welcome: "欢迎",
	what_we_are_doing: "我们在做什么",
	year: "年",
	year_lowercase: "年",
	yearly: "每年",
	yes: "是",
	you_dont_have_any_active_subscription: "您没有任何活动的订阅",
};
