import Model from "../_model";
import ContainerModel from "./container.model";
import ShipmentAttachmentModel from "./shipment_attachment.model";

export default class ShipmentModel extends Model {
	constructor(provider) {
		super(provider, "shipments");
		this.containers = new ContainerModel(provider);
		this.attachments = new ShipmentAttachmentModel(provider);
	}

	setProvider(value) {
		super.setProvider(value);
		this.containers.setProvider(value);
		this.attachments.setProvider(value);
	}

	_parse(object) {
		if (object.date) object.date = this.parseDateTime(object.date);
		if (object.attachments) this.attachments._parseList(object.attachments);
		if (object.containers) this.containers._parseList(object.containers);
		if (object.actions) for (const action of object.actions) action.date = this.parseDateTime(action.date);
		return super._parse(object);
	}

	/**
	 * @param { role, accessibilities } filter
	 */
	filter({ filter, pager, sorter, onSuccess, onFail }) {
		return super.filter({
			params: {
				role: filter ? filter.role : undefined,
				accessibilities: filter ? filter.accessibilities : undefined,
				bookingCode: filter ? filter.bookingCode : undefined,
				method: filter ? filter.method : undefined,
			},
			pager,
			sorter,
			onSuccess,
			onFail,
		});
	}

	find(id, { onSuccess, onFail }) {
		return super.find({ id, onSuccess, onFail });
	}

	create(instance, { onSuccess, onFail }) {
		const body = {
			method: instance.method,
			sender: {
				company: {
					referenceId: instance.sender.company.referenceId,
					name: instance.sender.company.name,
					countryId: instance.sender.company.countryId,
					isAbstract: instance.sender.company.isAbstract ?? undefined,
				},
			},
			receiver: {
				company: {
					referenceId: instance.receiver.company.referenceId,
					name: instance.receiver.company.name,
					countryId: instance.receiver.company.countryId,
					isAbstract: instance.receiver.company.isAbstract ?? undefined,
				},
			},
			currency: {
				code: instance.currency.code,
				name: instance.currency.name,
				symbol: instance.currency.symbol,
			},
			proformaInvoiceId: instance.proformaInvoiceId || undefined,
			bookingCode: instance.bookingCode,
			shippingTerm: {
				code: instance.shippingTerm.code,
				name: instance.shippingTerm.name,
			},
			date: instance.date.format("YYYY-MM-DD"),
		};
		if (instance.sender.customsAgency) {
			body.sender.customsAgency = {
				customsAgencyId: instance.sender.customsAgency.customsAgencyId,
				partnerId: instance.sender.customsAgency.partnerId,
				name: instance.sender.customsAgency.name,
			};
		}
		if (instance.receiver.customsAgency) {
			body.receiver.customsAgency = {
				customsAgencyId: instance.receiver.customsAgency.customsAgencyId,
				partnerId: instance.receiver.customsAgency.partnerId,
				name: instance.receiver.customsAgency.name,
			};
		}
		if (instance.method == "sea") {
			if (instance.loadingPort.code) {
				body.loadingPort = {
					portId: instance.loadingPort.portId,
					code: instance.loadingPort.code,
					name: instance.loadingPort.name,
					type: instance.loadingPort.type,
				};
			}
			if (instance.unloadingPort.code) {
				body.unloadingPort = {
					portId: instance.unloadingPort.portId,
					code: instance.unloadingPort.code,
					name: instance.unloadingPort.name,
					type: instance.unloadingPort.type,
				};
			}
		} else {
			body.loadingCountryId = instance.loadingCountryId || instance.loadingCountry.id;
			body.loadingPoint = instance.loadingPoint;
			body.unloadingCountryId = instance.unloadingCountryId || instance.unloadingCountry.id;
			body.unloadingPoint = instance.unloadingPoint;
		}
		return super.create(body, { onSuccess, onFail });
	}

	update(instance, { onSuccess, onFail }) {
		const body = {
			method: instance.method,
			currency: {
				code: instance.currency.code,
				name: instance.currency.name,
				symbol: instance.currency.symbol,
			},
			sender: {},
			receiver: {},
			bookingCode: instance.bookingCode || undefined,
			shippingTerm: instance.shippingTerm || undefined,
			date: instance.date.format("YYYY-MM-DD"),
			pdfTemplates: instance.pdfTemplates,
		};
		if (instance.method == "sea") {
			body.loadingPort = instance.loadingPort.code
				? {
						portId: instance.loadingPort.portId,
						code: instance.loadingPort.code,
						name: instance.loadingPort.name,
						type: instance.loadingPort.type,
				  }
				: undefined;
			body.unloadingPort = instance.unloadingPort.code
				? {
						portId: instance.unloadingPort.portId,
						code: instance.unloadingPort.code,
						name: instance.unloadingPort.name,
						type: instance.unloadingPort.type,
				  }
				: undefined;
		} else {
			body.loadingCountryId = instance.loadingCountryId || instance.loadingCountry.id;
			body.loadingPoint = instance.loadingPoint;
			body.unloadingCountryId = instance.unloadingCountryId || instance.unloadingCountry.id;
			body.unloadingPoint = instance.unloadingPoint;
		}
		if (
			instance.sender.customsAgency &&
			instance.sender.customsAgency.customsAgencyId &&
			instance.sender.customsAgency.partnerId
		) {
			body.sender.customsAgency = {
				customsAgencyId: instance.sender.customsAgency.customsAgencyId || null,
				partnerId: instance.sender.customsAgency.partnerId || null,
				name: instance.sender.customsAgency.name,
			};
		}
		if (
			instance.receiver.customsAgency &&
			instance.receiver.customsAgency.customsAgencyId &&
			instance.receiver.customsAgency.partnerId
		) {
			body.receiver.customsAgency = {
				customsAgencyId: instance.receiver.customsAgency.customsAgencyId || null,
				partnerId: instance.receiver.customsAgency.partnerId || null,
				name: instance.receiver.customsAgency.name,
			};
		}
		return super.update(body, { id: instance.id, onSuccess, onFail });
	}

	delete(id, { onSuccess, onFail }) {
		return super.delete({ id, onSuccess, onFail });
	}

	archive(instance, { onSuccess, onFail }) {
		return super._put(`${this._getUrl(instance.id)}/archive`, null, {
			onSuccess,
			onFail,
		});
	}

	unarchive(instance, { onSuccess, onFail }) {
		return super._put(`${this._getUrl(instance.id)}/unarchive`, null, {
			onSuccess,
			onFail,
		});
	}

	comment(instance, message, { onSuccess, onFail }) {
		return super._post(
			`${this._getUrl(instance.id)}/comment`,
			{ message },
			{
				onSuccess,
				onFail,
			}
		);
	}

	sendEmail(id, email, { onSuccess, onFail }) {
		return super._get(`${this._getUrl(id)}/send_email/${email}`, {
			onSuccess,
			onFail,
		});
	}
	deletePdfTemplate(id, templateId, { onSuccess, onFail }) {
		return super._delete(`${this._getUrl(id)}/pdf_templates/${templateId}`, { onSuccess, onFail });
	}
	updatePdfTemplate(id, templateId, template, { onSuccess, onFail }) {
		return super._put(`${this._getUrl(id)}/pdf_templates/${templateId}`, template, { onSuccess, onFail });
	}
}
