<template>
	<v-container class="py-0">
		<v-card elevation="0" class="ant-card px-4 py-2">
			<v-toolbar flat dense>
				<v-toolbar-title class="v-typography--title">
					{{ $t(isLandShipment ? "vehicles" : "containers") }}
				</v-toolbar-title>
			</v-toolbar>

			<v-divider class="mb-4 mt-1" />
			<v-card v-if="shipment.containers.length === 0" class="no-data-message text-center ant-card py-3" elevation="0">
				<v-icon class="icon-empty-state" size="28" color="black"></v-icon>
				<v-col>
					<p class="font-weight-bold mb-1 black--text v-typography--body-sm">
						{{ $t("no_entity", { entityName: $t("containers") }) }}
					</p>
					<span class="v-typography--body-sm no-data-message">
						{{ $t("no_entity_message", { entityName: $t("containers") }) }}
					</span>
				</v-col>
			</v-card>

			<div v-for="container in shipment.containers" :key="container.code" class="mt-4">
				<v-card elevation="0" outlined class="pa-4 ant-card">
					<v-row>
						<v-col cols="12" sm="6">
							<p class="grey--text text--darken-2">
								{{ $t(isLandShipment ? "$shipment.$vehicle.license_number" : "$shortening.number") }}:
								<span @click.prevent="containerDetailDialog.show(container)" class="primary--text underlined">
									{{ container.code }}
								</span>
							</p>
						</v-col>
						<v-col cols="12" sm="6" class="text-right">
							<v-btn text @click="containerDialog.show(container)">
								<v-icon size="15">icon-edit</v-icon>
								<span class="ml-1 v-typography--body-sm">
									{{ $t("edit") }}
								</span>
							</v-btn>
						</v-col>
					</v-row>
					<v-divider />

					<v-row class="mt-0" align="center" justify="center">
						<v-col cols="6" class="d-flex flex-column align-start">
							<div class="font-weight-bold text-start">
								{{ $t("estimated_arrival_day") }}
							</div>
							<div class="v-typography--body-sm text-center">
								{{ formattedDate(container.estimatedArrivalDay) }}
							</div>
						</v-col>
						<v-col cols="6" class="d-flex justify-end">
							<v-chip :style="getStatusStyles(container.status)">
								{{ $t(`$shipment.$containerStatus.${container.status}`) }}
							</v-chip>
						</v-col>
					</v-row>

					<v-divider class="mt-2" />

					<v-row class="mt-2">
						<v-col cols="6" class="d-flex align-center">
							<v-icon color="black" size="20" class="mr-2">icon-money</v-icon>
							<span class="v-typography--body1"> {{ $t("amount") }}: </span>
						</v-col>
						<v-col cols="6" class="text-right">
							<span class="v-typography--body1">
								{{
									container.totalAmount
										? container.totalAmount.toLocaleString($lang, {
												style: "currency",
												currency: shipment.currency.code,
										  })
										: ""
								}}
							</span>
						</v-col>
					</v-row>
				</v-card>
			</div>
			<v-row class="py-2" v-if="!customsAgency && iAmOwner">
				<v-col>
					<v-btn color="accent" class="ma-0" block rounded large elevation="0" outlined @click="containerDialog.show()">
						<v-icon size="22" class="mr-1">icon-plus</v-icon>
						<div class="d-flex justify-end align-center">
							{{ $t("add_item", { item: $t("containers") }) }}
						</div>
					</v-btn>
				</v-col>
			</v-row>
		</v-card>

		<container-dialog
			v-model="containerDialog.visibility"
			:item="containerDialog.item"
			:shipment="shipment"
			:i-am-owner="iAmOwner"
			:is-customs-agency="isCustomsAgency"
			@saved="containerDialog.saved"
			@deleted="containerDialog.deleted"
		/>

		<container-detail-dialog
			v-model="containerDetailDialog.visibility"
			:item="containerDetailDialog.item"
			:shipment="shipment"
			:iAmOwner="iAmOwner"
			:status-styles="getStatusStyles"
			@changed="containerDetailDialog.changed"
			@closed="containerDetailDialog.closed"
		/>
	</v-container>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import ContainerDialog from "../dialog/ContainerDialog.vue";
import ContainerDetailDialog from "../dialog/ContainerDetailDialog.vue";
import uiMixin from "../../../../store/interfaces/ui.mixin";

export default {
	mixins: [uiMixin],
	components: {
		ContainerDialog,
		ContainerDetailDialog,
	},
	props: {
		shipment: {
			type: Object,
			required: true,
		},
		isLandShipment: {
			type: Boolean,
			required: true,
		},
		customsAgency: {
			type: Object,
			default: null,
		},
		iAmOwner: {
			type: Boolean,
			required: true,
		},
	},
	data() {
		return {
			containerListHeaders: [
				{
					text: this.$t("$shortening.number"),
					value: "code",
					sortable: false,
				},
				{
					text: this.$t("content"),
					value: "content",
					sortable: false,
				},
				{
					text: this.$t("status"),
					value: "status",
					sortable: false,
				},
				{
					text: this.$t("estimated_arrival_day"),
					value: "estimatedArrivalDay",
					sortable: false,
				},
				{
					text: this.$t("total_amount"),
					value: "totalAmount",
					sortable: false,
				},
				{
					value: "buttons",
					align: "right",
					sortable: false,
					width: 145,
				},
			],
			containerDialog: {
				visibility: false,
				item: null,
				show: (item) => {
					this.containerDialog.item = item;
					this.containerDialog.visibility = true;
				},
				hide: () => {
					this.containerDialog.item = null;
					this.containerDialog.visibility = false;
				},
				saved: () => {
					this.$emit("show-snackbar", {
						message: this.$t(this.containerDialog.item ? "$message.updated" : "$message.created", {
							item: this.$t("container"),
						}),
					});
					this.containerDialog.hide();
					this.$emit("reload-shipment");
				},
				deleted: () => {
					this.containerDialog.hide();
					this.$emit("show-snackbar", {
						message: this.$t("$message.deleted", {
							item: this.$t("container"),
						}),
					});
					this.$emit("reload-shipment");
				},
			},
			containerDetailDialog: {
				visibility: false,
				item: null,
				hasChange: false,
				show: (item) => {
					this.containerDetailDialog.item = item;
					this.containerDetailDialog.visibility = true;
				},
				hide: () => {
					this.containerDetailDialog.item = null;
					this.containerDetailDialog.visibility = false;
					this.containerDetailDialog.hasChange = false;
				},
				changed: () => {
					this.containerDetailDialog.hasChange = true;
				},
				closed: () => {
					if (this.containerDetailDialog.hasChange) {
						this.$emit("reload-shipment");
					}
				},
			},
		};
	},
	computed: {
		...mapState(["countries", "corporation"]),
		...mapGetters(["company"]),
		isCustomsAgency() {
			return !!(this.customsAgency && this.customsAgency.id);
		},
	},
	methods: {
		formattedDate(date) {
			return date
				? new Date(date).toLocaleDateString(this.$lang, {
						year: "numeric",
						month: "2-digit",
						day: "2-digit",
				  })
				: "";
		},
		containerNoteAction(container) {
			this.$emit("show-alert-dialog", {
				title: this.$t("note"),
				message: container.note,
				buttonText: this.$t("hide"),
			});
		},
		getStatusStyles(status) {
			switch (status) {
				case "reserved":
					return {
						backgroundColor: "rgba(128, 128, 128, 0.5)",
						color: "#606060",
					};
				case "on_load":
					return {
						backgroundColor: "rgba(0, 123, 255, 0.5)",
						color: "#0056b3",
					};
				case "on_way":
					return {
						backgroundColor: "rgba(255, 165, 0, 0.5)",
						color: "#cc8400",
					};
				case "arrived":
					return {
						backgroundColor: "rgba(0, 128, 0, 0.5)",
						color: "#006400",
					};
				case "off_load":
					return {
						backgroundColor: "rgba(255, 0, 0, 0.5)",
						color: "#8b0000",
					};
				default:
					return {
						backgroundColor: "rgba(128, 128, 128, 0.5)",
						color: "#808080",
					};
			}
		},
	},
};
</script>
<style scoped>
.no-data-message {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: rgba(0, 0, 0, 0.54); /* Adjust color as needed */
}

.icon-empty-state {
	margin-bottom: 10px;
}

.text-center {
	text-align: center;
}
</style>
