<template>
	<v-card class="mb-4 py-2" elevation="0">
		<v-row class="align-center flex-wrap">
			<v-col cols="2" sm="1" class="d-flex justify-center align-center py-0">
				<v-avatar color="grey lighten-3" class="ma-2" size="40">
					<template v-if="icon">
						<v-icon color="grey darken-1" size="20">{{ icon }}</v-icon>
					</template>
					<template v-else>
						<span class="grey--text text-caption">{{ iconText }}</span>
					</template>
				</v-avatar>
			</v-col>

			<v-col cols="8" sm="9">
				<div class="text-truncate">
					<div class="font-weight-bold text-truncate">{{ text }}</div>
					<span v-if="note" class="pa-1 d-block text-truncate">
						{{ note }}
					</span>
				</div>
			</v-col>

			<v-col cols="2" sm="2" class="d-flex justify-end text-caption">
				{{ date }}
			</v-col>
		</v-row>
	</v-card>
</template>

<script>
export default {
	name: "ActionCard",
	props: {
		iconText: {
			type: String,
			default: "",
		},
		icon: {
			type: String,
			default: "",
		},
		text: {
			type: String,
			required: true,
		},
		note: {
			type: String,
			default: null,
		},
		date: {
			type: String,
			required: true,
		},
	},
};
</script>

<style scoped>
.v-avatar {
	display: flex;
	align-items: center;
	justify-content: center;
}

.text-truncate {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

@media (max-width: 600px) {
	.v-card {
		padding: 8px;
	}
	.v-avatar {
		margin-right: 8px;
	}
	.text-caption {
		font-size: 12px;
	}
	.font-weight-bold {
		font-size: 14px;
	}
}
</style>
