<template>
	<landing-page-template>
		<landing-faq id="faq" :isMobile="isMobile" />
	</landing-page-template>
</template>

<script>
import uiMixin from "../../../../store/interfaces/ui.mixin";
import LandingPageTemplate from "../../../templates/LandingPageTemplate.vue";
import LandingFaq from "../partial/LandingQuestions.vue";

export default {
	components: {
		LandingPageTemplate,
		LandingFaq,
	},
	mixins: [uiMixin],
};
</script>
