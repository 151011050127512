import Model from "../_model";
import ProformaInvoiceProductModel from "./proforma_invoice_product.model";
import ProformaInvoiceAttachment from "./proforma_invoice_attachment.model";

export default class ProformaInvoiceModel extends Model {
	constructor(provider) {
		super(provider, "proforma_invoices");
		this.products = new ProformaInvoiceProductModel(provider);
		this.attachments = new ProformaInvoiceAttachment(provider);
	}

	setProvider(value) {
		super.setProvider(value);
		this.products.setProvider(value);
		this.attachments.setProvider(value);
	}

	_parse(object) {
		if (object.date) object.date = super.parseDateTime(object.date);
		return super._parse(object);
	}

	/**
	 * @param { role, referenceId, statuses } filter
	 */
	filter({ filter, pager, sorter }, { onSuccess, onFail }) {
		return super.filter({
			params: {
				role: filter ? filter.role : undefined,
				referenceId: filter ? filter.referenceId : undefined,
				status: filter && Array.isArray(filter.statuses) ? filter.statuses.join() : undefined,
			},
			pager,
			sorter,
			onSuccess,
			onFail,
		});
	}

	find(id, { onSuccess, onFail }) {
		return super.find({ id, onSuccess, onFail });
	}

	create(instance, { onSuccess, onFail }) {
		const body = {
			sender: {
				referenceId: instance.sender.referenceId,
				isAbstract: instance.sender.isAbstract ?? undefined,
				name: instance.sender.name,
				countryId: instance.sender.countryId,
			},
			receiver: {
				referenceId: instance.receiver.referenceId,
				isAbstract: instance.receiver.isAbstract ?? undefined,
				name: instance.receiver.name,
				countryId: instance.receiver.countryId,
			},
			date: instance.date.format("YYYY-MM-DD"),
			no: instance.no ?? null,
			shipmentMethod: instance.shipmentMethod,
			shippingTerm: instance.shippingTerm
				? {
						code: instance.shippingTerm.code,
						name: instance.shippingTerm.name,
				  }
				: null,
			currency: {
				code: instance.currency.code,
				name: instance.currency.name,
				symbol: instance.currency.symbol,
			},
		};
		if (instance.shipmentMethod == "sea") {
			body.loadingPort = instance.loadingPort
				? {
						code: instance.loadingPort.code,
						name: instance.loadingPort.name,
						portId: instance.loadingPort.portId,
						type: instance.loadingPort.type,
				  }
				: null;
			body.unloadingPort = instance.unloadingPort
				? {
						code: instance.unloadingPort.code,
						name: instance.unloadingPort.name,
						portId: instance.unloadingPort.portId,
						type: instance.unloadingPort.type,
				  }
				: null;
		} else {
			body.loadingPoint = instance.loadingPoint || null;
			body.unloadingPoint = instance.unloadingPoint || null;
		}
		body.loadingCountryId = instance.loadingCountryId || (instance.loadingCountry ? instance.loadingCountry.id : null);
		body.unloadingCountryId =
			instance.unloadingCountryId || (instance.unloadingCountry ? instance.unloadingCountry.id : null);

		// if (instance.products)
		// 	for (const product of instance.products)
		// 		body.products.push({
		// 			name: product.name,
		// 			unitCode: product.unitCode,
		// 			quantity: product.quantity,
		// 			amount: product.amount,
		// 		});
		return super.create(body, { onSuccess, onFail });
	}

	update(instance, { onSuccess, onFail }) {
		var isSeaShipment = instance.shipmentMethod == "sea";
		const body = {
			pdfTemplates: instance.pdfTemplates,
			date: instance.date.format("YYYY-MM-DD"),
			no: instance.no,
			shipmentMethod: instance.shipmentMethod,
			sender: {
				referenceId: instance.sender.referenceId,
				isAbstract: instance.sender.isAbstract ?? undefined,
				countryId: instance.sender.countryId,
				name: instance.sender.name,
			},
			receiver: {
				referenceId: instance.receiver.referenceId,
				isAbstract: instance.receiver.isAbstract ?? undefined,
				countryId: instance.receiver.countryId,
				name: instance.receiver.name,
			},
			loadingCountryId: instance.loadingCountry.id,
			unloadingCountryId: instance.unloadingCountry.id,
			loadingPort: isSeaShipment
				? {
						portId: instance.loadingPort.portId,
						code: instance.loadingPort.code,
						name: instance.loadingPort.name,
						type: instance.loadingPort.type,
				  }
				: null,
			unloadingPort: isSeaShipment
				? {
						portId: instance.unloadingPort.portId,
						code: instance.unloadingPort.code,
						name: instance.unloadingPort.name,
						type: instance.unloadingPort.type,
				  }
				: null,
			loadingPoint: isSeaShipment ? null : instance.loadingPoint,
			unloadingPoint: isSeaShipment ? null : instance.unloadingPoint,
			shippingTerm: {
				code: instance.shippingTerm.code,
				name: instance.shippingTerm.name,
			},
			currency: {
				code: instance.currency.code,
				name: instance.currency.name,
				symbol: instance.currency.symbol,
			},
		};
		return super.update(body, { id: instance.id, onSuccess, onFail });
	}

	delete(id, { onSuccess, onFail }) {
		return super.delete({ id, onSuccess, onFail });
	}

	send(id, { message, onSuccess, onFail }) {
		return super._put(`${this._getUrl(id)}/send`, { message }, { onSuccess, onFail });
	}

	approve(id, { message, onSuccess, onFail }) {
		return super._put(
			`${this._getUrl(id)}/approve`,
			{ message },
			{
				onSuccess,
				onFail,
			}
		);
	}

	markAsProcessed(id, { message, onSuccess, onFail }) {
		return super._put(
			`${this._getUrl(id)}/mark_as_processed`,
			{ message },
			{
				onSuccess,
				onFail,
			}
		);
	}

	demandRevise(id, { message, onSuccess, onFail }) {
		return super._put(
			`${this._getUrl(id)}/demand_revise`,
			{ message },
			{
				onSuccess,
				onFail,
			}
		);
	}

	reject(id, { message, onSuccess, onFail }) {
		return super._put(
			`${this._getUrl(id)}/reject`,
			{ message },
			{
				onSuccess,
				onFail,
			}
		);
	}

	revoke(id, { message, onSuccess, onFail }) {
		return super._put(
			`${this._getUrl(id)}/revoke`,
			{ message },
			{
				onSuccess,
				onFail,
			}
		);
	}
	deletePdfTemplate(id, templateId, { onSuccess, onFail }) {
		return super._delete(`${this._getUrl(id)}/pdf_templates/${templateId}`, { onSuccess, onFail });
	}
	updatePdfTemplate(id, templateId, template, { onSuccess, onFail }) {
		return super._put(`${this._getUrl(id)}/pdf_templates/${templateId}`, template, { onSuccess, onFail });
	}
}
