<template>
	<ext-dialog
		v-model="value"
		:title="$t('send_email')"
		:primaryButtonText="$t('send')"
		:dialogType="'large'"
		@primaryButtonAction="sendAction"
		@closed="closedAction"
	>
		<template v-slot:body>
			<v-row class="mt-2">
				<v-col cols="12" sm="4">
					<info-section
						icon="icon-company"
						:title="$t('sender')"
						:content="[
							shipment.sender.company.name,
							shipment.sender.customsAgency ? 'Custom: ' + shipment.sender.customsAgency.name : null,
						]"
					/>
				</v-col>
				<v-col cols="12" sm="4">
					<info-section
						icon="icon-company"
						:title="$t('receiver')"
						:content="[
							shipment.receiver.company.name,
							shipment.receiver.customsAgency ? 'Custom: ' + shipment.receiver.customsAgency.name : null,
						]"
					/>
				</v-col>
				<v-col cols="12" sm="4">
					<info-section
						:icon="getShipmentMethodIcon(shipment.method)"
						:title="$t('method')"
						:content="$t(`$shipment.$method.${shipment.method}`)"
					/>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" sm="4">
					<info-section
						icon="icon-load"
						:title="isSeaShipment ? $t('loading_port') : $t('loading_point')"
						:content="[
							shipment.loadingCountry ? $t(`$country.${shipment.loadingCountry.name}`) : '',
							isSeaShipment ? (shipment.loadingPort ? shipment.loadingPort.name : '') : shipment.loadingPoint,
						]"
					/>
				</v-col>
				<v-col cols="12" sm="4">
					<info-section
						icon="icon-unload"
						:title="isSeaShipment ? $t('unloading_port') : $t('unloading_point')"
						:content="[
							shipment.unloadingCountry ? $t(`$country.${shipment.unloadingCountry.name}`) : '',
							isSeaShipment ? (shipment.unloadingPort ? shipment.unloadingPort.name : '') : shipment.unloadingPoint,
						]"
					/>
				</v-col>
				<v-col cols="12" sm="4">
					<info-section
						icon="icon-shuffle"
						:title="$t('shipping_term')"
						:content="shipment.shippingTerm ? `${shipment.shippingTerm.name} (${shipment.shippingTerm.code})` : ''"
					/>
				</v-col>
			</v-row>
			<v-divider class="my-4" />
			<v-row align="center">
				<v-col cols="4">
					<v-btn color="primary" outlined large @click="copyShipmentLink">
						<v-icon left>icon-copy</v-icon>
						{{ $t("copy_link") }}
					</v-btn> </v-col
				><v-col cols="8">
					<v-form ref="form" v-model="valid">
						<v-text-field
							v-model="email"
							:label="$t('email')"
							prepend-icon="icon-email"
							:rules="rules.email"
							:disabled="loading"
						/>
					</v-form>
				</v-col>
			</v-row>
		</template>
	</ext-dialog>
</template>

<script>
import uiMixin from "../../../../store/interfaces/ui.mixin";
import remote from "../../../../data/remote";
import { mapState } from "vuex";
import ExtDialog from "../../../partials/dialogs/ExtDialog.vue";
import InfoSection from "../partial/InfoSection.vue";
import { iconHelper as iconHelperMixin } from "../../../mixins/utils.mixin";

export default {
	components: { ExtDialog, InfoSection },
	props: {
		value: Boolean,
		customerPartner: Object,
		shipmentId: String,
		shipment: {
			type: Object,
			required: true,
		},
	},
	mixins: [uiMixin, iconHelperMixin],
	data() {
		return {
			email: "",
			valid: false,
			loading: true,
			emailLoaded: false,
			rules: {
				email: [
					(v) => {
						if (!v)
							return this.$t("$validation.required", {
								item: this.$t("email"),
							});
						else if (!/.+@.+\..+/.test(v)) return this.$t("$validation.invalid", { item: this.$t("email") });
						return true;
					},
				],
			},
		};
	},
	computed: {
		...mapState(["corporation"]),
		isSeaShipment() {
			return this.shipment.method === "sea";
		},
	},
	watch: {
		value(val) {
			//TODO: isAbstract kontrolü eklenecek. Ona göre request company ya da partners'a gönderilecek.
			if (val) this.loadPartnerInfo();
			else this.closedAction();
		},
	},
	methods: {
		loadPartnerInfo() {
			this.loading = true;
			if (this.customerPartner.isAbstract)
				remote.corporations.partners.find(this.corporation.id, this.customerPartner.referenceId, {
					onSuccess: (result) => {
						this.email = result.email || "";
						this.loading = false;
					},
					onFail: () => (this.loading = false),
				});
			else
				remote.corporations.partners.findByCorporationId(this.corporation.id, this.customerPartner.referenceId, {
					onSuccess: (result) => {
						this.email = result.email || "";
						this.loading = false;
					},
					onFail: () => (this.loading = false),
				});
		},
		sendAction() {
			this.$refs.form.validate();
			if (this.valid) {
				this.loading = true;
				remote.shipments.sendEmail(this.shipmentId, this.email, {
					onSuccess: (result) => {
						this.showSnackBar({
							message: this.$t("$message.sent_email", {
								value: this.email,
							}),
						});
						this.closedAction();
						this.loading = false;
					},
					onFail: () => (this.loading = false),
				});
			}
		},
		closedAction() {
			this.email = "";
			this.emailLoaded = false;
			if (this.$refs.form) {
				this.$refs.form.resetValidation();
			}
			this.$emit("closed");
		},
		copyShipmentLink() {
			const link = `${window.location.origin}/shipment/${this.shipmentId}`;
			navigator.clipboard
				.writeText(link)
				.then(() => {
					this.showSnackBar({
						message: this.$t("link_copied", { value: link }),
					});
				})
				.catch(() => {
					this.showSnackBar({
						message: this.$t("link_copy_failed"),
					});
				});
		},
	},
};
</script>
