<template>
	<landing-page-template>
		<landing-process id="process" :isMobile="isMobile" />
	</landing-page-template>
</template>

<script>
import uiMixin from "../../../../store/interfaces/ui.mixin";
import LandingPageTemplate from "../../../templates/LandingPageTemplate.vue";
import LandingProcess from "../partial/LandingProcess.vue";

export default {
	components: {
		LandingPageTemplate,
		LandingProcess,
	},
	mixins: [uiMixin],
};
</script>
