<template>
	<ext-dialog
		v-model="value"
		:title="$t('containers')"
		:primaryButtonText="null"
		:secondaryButtonText="null"
		dialogType="large"
		@closed="onDialogClosed"
	>
		<template #body>
			<v-card-text v-if="containerItems.length === 0">
				{{ $t("no_entity", { entityName: $t("containers") }) }}
			</v-card-text>

			<common-table
				v-else
				class="pb-6 pt-6"
				:options.sync="tableOptions"
				:headers="productContainerListHeaders"
				:items="containerItems"
				:hide-pagination="true"
				:entity-name="$t('containers')"
			>
				<template #item.code="{ item }">
					<span class="primary--text underlined" @click="selectContainer(item.container)">
						{{ item.code }}
					</span>
				</template>

				<template #item.quantity="{ item }"> {{ item.quantity }} {{ item.unitCode }} </template>

				<template #item.price="{ item }">
					{{ formatCurrency(item.price) }}
				</template>

				<template #item.total_amount="{ item }">
					<div class="d-flex justify-end">
						{{ formatCurrency(item.total_amount) }}
					</div>
				</template>
			</common-table>
		</template>
	</ext-dialog>
</template>

<script>
import ExtDialog from "../../../partials/dialogs/ExtDialog.vue";
import CommonTable from "../../../partials/CommonTable.vue";

export default {
	name: "ProductContainersDialog",
	components: { ExtDialog, CommonTable },
	props: {
		value: Boolean,
		product: {
			type: Object,
			default: null,
		},
		shipment: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			tableOptions: {
				page: 1,
				itemsPerPage: 50,
			},
			productContainerListHeaders: [
				{ text: this.$t("$shortening.number"), value: "code", sortable: false },
				{ text: this.$t("name"), value: "name", sortable: false },
				{ text: this.$t("quantity"), value: "quantity", sortable: false },
				{ text: this.$t("amount"), value: "price", sortable: false },
				{ text: this.$t("total_amount"), value: "total_amount", sortable: false },
			],
		};
	},
	computed: {
		filteredContainers() {
			if (!this.product || !this.shipment || !this.shipment.containers) return [];
			return this.shipment.containers.filter((container) => {
				if (!container.products) return false;
				return container.products.some((p) => {
					const pName = p.name[this.$i18n.locale] || p.name._default;
					return (
						pName === this.product.name && p.amount === this.product.amount && p.unitCode === this.product.unitCode
					);
				});
			});
		},
		containerItems() {
			return this.filteredContainers.map((container) => {
				const containerProduct = container.products.find((p) => {
					const pName = p.name[this.$i18n.locale] || p.name._default;
					return (
						pName === this.product.name && p.amount === this.product.amount && p.unitCode === this.product.unitCode
					);
				});

				return {
					container,
					code: container.code,
					name: this.product.name,
					quantity: containerProduct ? containerProduct.quantity : 0,
					price: containerProduct ? containerProduct.amount : 0,
					unitCode: containerProduct ? containerProduct.unitCode : "",
					total_amount: containerProduct ? containerProduct.quantity * containerProduct.amount : 0,
				};
			});
		},
	},
	methods: {
		selectContainer(container) {
			this.$emit("select-container", container);
			this.$emit("input", false);
		},
		onDialogClosed() {
			this.$emit("input", false);
		},
		formatCurrency(value) {
			if (!this.shipment || !this.shipment.currency) return value;
			return value.toLocaleString(this.$lang, {
				style: "currency",
				currency: this.shipment.currency.code,
			});
		},
	},
};
</script>

<style scoped>
.primary--text.underlined {
	cursor: pointer;
	text-decoration: underline;
}
</style>
