<template>
	<landing-page-template>
		<landing-benefits id="benefits" :isMobile="isMobile" />
	</landing-page-template>
</template>

<script>
import uiMixin from "../../../../store/interfaces/ui.mixin";
import LandingPageTemplate from "../../../templates/LandingPageTemplate.vue";
import LandingBenefits from '../partial/LandingBenefits.vue';

export default {
	components: {
		LandingPageTemplate,
		LandingBenefits,
	},
	mixins: [uiMixin],
};
</script>
