<template>
	<v-container>
		<v-card class="ant-card pa-2" elevation="0">
			<v-toolbar flat dense>
				<v-toolbar-title class="v-typography--title">{{ $t("activities") }}</v-toolbar-title>
			</v-toolbar>

			<v-divider class="mb-4"></v-divider>

			<!--
      <v-form ref="form" v-model="valid">
        <v-text-field
          v-model="message"
          :label="`${$t('$proformaInvoice.leave_message')}...`"
          @keyup.enter="commentSaveAction"
          :rules="rules.message"
          counter="250"
        >
          <template v-slot:append>
            <v-btn
              color="primary"
              class="mb-1"
              @click="commentSaveAction"
              :disabled="message.trim().length === 0"
              :loading="savingMessage"
            >
              {{ $t("save") }}
            </v-btn>
          </template>
        </v-text-field>
      </v-form>
      -->

			<v-card-text class="py-0">
				<div v-if="list && list.length">
					<activity-card
						v-for="item in list"
						:key="item.id"
						:icon="getActivityIcon(item.type)"
						:text="item.text"
						:note="item.note"
						:date="item.date"
					/>
				</div>
				<div v-else>
					<v-alert type="info">{{ $t("no_actions_available") }}</v-alert>
				</div>
			</v-card-text>
		</v-card>
	</v-container>
</template>

<script>
import { mapState } from "vuex";
// Import the remote API if you need to enable commenting functionality
// import remote from "../../../../data/remote";
import ActivityCard from "../../shipment/partial/ActivityCard.vue";

export default {
	name: "ProformaInvoiceActionTimeline",
	components: {
		ActivityCard,
	},
	props: {
		value: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			list: [],
			message: "",
			savingMessage: false,
			valid: true,
			rules: {
				message: [
					(v) => {
						if (v && v.length > 250)
							return this.$t("$validation.max_length", {
								item: this.$t("message"),
								length: "250",
							});
						return true;
					},
				],
			},
			activityIconMap: {
				send: { icon: "icon-send" },
				revoke: { icon: "icon-undo" },
				approve: { icon: "icon-file-add" },
				demand_revise: { icon: "icon-edit" },
				reject: { icon: "icon-delete" },
				mark_as_processed: { icon: "icon-folder" },
				comment: { icon: "icon-unload" },
				create: { icon: "icon-plus" },
				update: { icon: "icon-edit" },
				comment: { icon: "icon-unload" },
				create_attachment: { icon: "icon-plus" },
				update_attachment: { icon: "icon-edit" },
				delete_attachment: { icon: "icon-delete" },
				create_container: { icon: "icon-plus" },
				update_container: { icon: "icon-edit" },
				delete_container: { icon: "icon-delete" },
			},
		};
	},
	computed: {
		...mapState("auth", ["user"]),
	},
	watch: {
		value: {
			handler() {
				this.handleProps();
			},
			immediate: true,
		},
	},
	methods: {
		handleProps() {
			this.list = [];
			const actions = [...this.value.actions].reverse();
			actions.forEach((action) => {
				const item = {
					text: "",
					note: null,
					date: action.date.substring(0, 10) + " " + action.date.substring(11, 16),
					type: action.type,
					id: action.id,
					createdBy: action.createdBy,
				};

				const operationActions = ["send", "revoke", "approve", "demand_revise", "reject", "mark_as_processed"];

				if (action.type === "comment") {
					item.text = `${action.createdBy.firstName} ${action.createdBy.lastName} ${this.$t("left_a_comment")}`;
					item.note = `"${action.referenceText}"`;
				} else {
					const name = `${action.createdBy.firstName} ${action.createdBy.lastName}`;
					if (this.value.receiver.isAbstract && operationActions.includes(action.type)) {
						item.text = this.$t(`$proformaInvoice.$actionDescription.${action.type}`, { name });
					} else {
						item.text = this.$t(`$proformaInvoice.$actionDescription.${action.type}`, { name });
					}
					item.note = action.referenceText;
				}

				this.list.push(item);
			});
		},
		getActivityIcon(type) {
			return this.activityIconMap[type]?.icon || "icon-edit";
		},
		/*
    commentSaveAction() {
      if (this.valid) {
        if (this.message.trim().length > 0) {
          this.savingMessage = true;
          remote.proformaInvoices.comment(this.value, this.message, {
            onSuccess: (proformaInvoice) => {
              this.$emit("input", proformaInvoice);
              this.savingMessage = false;
              this.message = "";
            },
            onFail: () => {
              this.savingMessage = false;
            },
          });
        }
      } else {
        this.$refs.form.validate();
      }
    },
    */
	},
	created() {
		this.handleProps();
	},
};
</script>
