<template>
	<ext-dialog
		v-model="dialogVisibility"
		:title="$t('template_actions')"
		:primaryButtonText="$t('save')"
		@primaryButtonAction="onSave"
		:secondaryButtonText="$t('cancel')"
		@secondaryButtonAction="hide"
		@closed="hide"
	>
		<template #body>
			<v-form ref="dialogForm" @submit.prevent>
				<span>
					{{ $t("template_save_info") }}
				</span>
				<v-radio-group v-model="isSaveAsNew" :row="true">
					<v-radio
						:disabled="existingTemplates.length === 0"
						:value="false"
						:label="$t('edit_template')"
						class="ml-2"
						on-icon="icon-radio-on"
						off-icon="icon-radio-off"
					/>
					<v-radio
						:value="true"
						:label="$t('template_overwrite')"
						class="ml-2"
						on-icon="icon-radio-on"
						off-icon="icon-radio-off"
					/>
				</v-radio-group>

				<v-select
					:items="existingTemplates"
					item-text="name"
					item-value="_id"
					:label="$t('select_template_to_overwrite')"
					v-model="selectedTemplateToOverride"
					:rules="selectRules"
					dense
					v-if="!isSelectDisabled"
					hide-details
					outlined
					append-icon="icon-angle-down"
					class="pt-1 pb-2"
					:menu-props="{ offsetY: true }"
				>
					<template v-slot:item="{ item }">
						{{ item.name }}
					</template>
				</v-select>
				<v-text-field
					v-if="isSaveAsNew"
					v-model="templateName"
					:label="$t('template_name')"
					:rules="nameRules"
					@keydown.enter.prevent="onSave"
					dense
					class="py-2"
				/>
			</v-form>
		</template>
	</ext-dialog>
</template>

<script>
import ExtDialog from "../../../partials/dialogs/ExtDialog.vue";

export default {
	name: "AddPdfTemplateDialog",
	components: {
		ExtDialog,
	},
	props: {
		visibility: {
			type: Boolean,
			default: false,
		},
		existingTemplates: {
			type: Array,
			default: () => [],
		},
		initialSelectedTemplateId: {
			type: [String, Number],
			default: null,
		},
	},
	data() {
		return {
			dialogVisibility: this.visibility,
			templateName: "",
			isSaveAsNew: false,
			selectedTemplateToOverride: null,
		};
	},
	watch: {
		visibility(newVal) {
			this.dialogVisibility = newVal;
			if (newVal) {
				this.initializeDialog();
			} else {
				this.resetDialog();
			}
		},
		dialogVisibility(newVal) {
			this.$emit("update:visibility", newVal);
		},
		existingTemplates(newTemplates) {
			if (newTemplates.length === 0) {
				this.isSaveAsNew = true;
			} else {
				if (!this.initialSelectedTemplateId) {
					this.isSaveAsNew = false;
				}
				if (this.existingTemplates.length > 0 && !this.isSaveAsNew) {
					if (this.initialSelectedTemplateId) {
						this.selectedTemplateToOverride = this.initialSelectedTemplateId;
					} else {
						this.selectedTemplateToOverride = null;
					}
				}
			}
		},
	},
	computed: {
		isSelectDisabled() {
			return this.isSaveAsNew || this.existingTemplates.length === 0;
		},
		isCheckboxDisabled() {
			return this.existingTemplates.length === 0;
		},
		selectRules() {
			return !this.isSaveAsNew
				? [(v) => !!v || this.$t("$validation.required", { item: this.$t("select_template_to_overwrite") })]
				: [];
		},
		nameRules() {
			return this.isSaveAsNew
				? [(v) => !!v || this.$t("$validation.required", { item: this.$t("template_name") })]
				: [];
		},
	},
	methods: {
		hide() {
			this.dialogVisibility = false;
			if (this.$refs.dialogForm) {
				this.$refs.dialogForm.resetValidation();
			}
			this.resetDialog();
		},
		resetDialog() {
			this.templateName = "";
			this.isSaveAsNew = this.existingTemplates.length === 0;
			this.selectedTemplateToOverride = this.initialSelectedTemplateId || null;
		},
		initializeDialog() {
			if (this.existingTemplates.length === 0) {
				this.isSaveAsNew = true;
				this.selectedTemplateToOverride = null;
			} else if (this.initialSelectedTemplateId) {
				const exists = this.existingTemplates.some((template) => template._id === this.initialSelectedTemplateId);
				if (exists) {
					this.selectedTemplateToOverride = this.initialSelectedTemplateId;
					this.isSaveAsNew = false;
				} else {
					this.selectedTemplateToOverride = null;
					this.isSaveAsNew = false;
				}
			} else {
				this.isSaveAsNew = false;
				this.selectedTemplateToOverride = null;
			}
		},
		onSave() {
			const isValid = this.$refs.dialogForm.validate();
			if (!isValid) {
				return;
			}
			if (this.isSaveAsNew) {
				this.$emit("saved", { action: "save-as-new", name: this.templateName });
			} else {
				const selectedTemplate = this.existingTemplates.find((t) => t._id === this.selectedTemplateToOverride);
				this.$emit("saved", {
					action: "override",
					id: this.selectedTemplateToOverride,
					name: selectedTemplate ? selectedTemplate.name : "",
				});
			}
			this.hide();
		},
	},
	mounted() {
		if (this.dialogVisibility) {
			this.initializeDialog();
		}
	},
};
</script>
