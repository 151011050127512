<template>
	<ext-dialog
		v-model="value"
		:title="$t(isLandShipment ? 'vehicle' : 'container')"
		dialogType="large"
		@closed="closeAction"
	>
		<template slot="body" v-if="currentItem">
			<v-container>
				<v-row class="px-4">
					<v-col cols="6" sm="3">
						<title-value-list-item
							:value="currentItem.code"
							:title="$t(isLandShipment ? '$shipment.$vehicle.license_number' : '$shortening.number')"
						/>
					</v-col>
					<v-col cols="6" sm="3" class="d-flex flex-column align-center">
						<span class="mb-1 v-typography--body-sm black--text">{{ $t("status") }}</span>
						<v-chip :style="statusStyles(currentItem.status)">
							{{ $t(`$shipment.$containerStatus.${currentItem.status}`) }}
						</v-chip>
					</v-col>

					<v-col cols="6" sm="3">
						<title-value-list-item
							:value="currentItem.estimatedArrivalDay ? currentItem.estimatedArrivalDay.format($t('$format.date')) : ''"
							:title="$t('estimated_arrival_day')"
						/>
					</v-col>
					<v-col cols="6" sm="3" v-if="totalAmount != null">
						<title-value-list-item
							:value="
								totalAmount.toLocaleString($lang, {
									style: 'currency',
									currency: shipment.currency.code,
								})
							"
							:title="$t('total_amount')"
						/>
					</v-col>
					<!-- <v-col cols="12" v-if="currentItem.note">
						<title-value-list-item :value="currentItem.note" :title="$t('note')" />
					</v-col> -->
				</v-row>

				<!-- Products -->
				<v-toolbar flat dense>
					<v-toolbar-title class="mb-2 font-weight-bold">
						{{ $t("products") }}
					</v-toolbar-title>
				</v-toolbar>
				<common-table
					:headers="productHeaders"
					:items="currentItem.products"
					:items-length="currentItem.products.length"
					:options.sync="tableOptions"
					:entity-name="$t('product')"
					:hidePagination="true"
					@delete-item="deleteProductAction"
					@row-click="productDialog.show"
				>
					<template #item.name="{ item }">
						{{ item.name[$lang] && item.name[$lang].length ? item.name[$lang] : item.name["_default"] }}
					</template>
					<template #item.quantity="{ item }"> {{ item.quantity }} {{ item.unitCode }} </template>
					<template #item.amount="{ item }">
						{{
							item.amount.toLocaleString($lang, {
								style: "currency",
								currency: shipment.currency.code,
							})
						}}
					</template>
				</common-table>
				<v-row class="mt-1 px-2" align="center">
					<v-col>
						<v-btn
							v-if="isEditable && iAmOwner && !customsAgency"
							class="ma-0"
							block
							rounded
							color="primary"
							elevation="0"
							outlined
							@click="productDialog.show()"
						>
							<v-icon size="20" class="mr-1"> icon-plus </v-icon>
							{{ $t("add_item", { item: $t("products") }) }}
						</v-btn>
					</v-col>
					<v-col v-if="currentItem.products.length" class="text-right">
						<span class="pr-4">
							<span class="v-typography--body black--text">
								{{
									`${$t("total")}: ${totalProductAmount.toLocaleString($lang, {
										style: "currency",
										currency: shipment.currency.code,
									})} `
								}}
							</span>
						</span>
					</v-col>
				</v-row>

				<container-product-dialog
					v-if="iAmOwner && !customsAgency"
					v-model="productDialog.visibility"
					:item="productDialog.item"
					:shipment="shipment"
					:container="currentItem"
					:i-am-sender-company="iAmSenderCompany"
					@saved="productDialog.saved"
					@deleted="productDialog.deleted"
				/>

				<!-- Expenses -->
				<v-toolbar flat dense class="mt-2">
					<v-toolbar-title class="mb-2">
						{{ $t("expenses") }}
					</v-toolbar-title>
				</v-toolbar>
				<common-table
					:headers="expenseHeaders"
					:items="currentItem.expenses"
					:items-length="currentItem.expenses.length"
					:options.sync="tableOptions"
					:entity-name="$t('expense')"
					:hidePagination="true"
					@row-click="expenseDialog.show"
					@delete-item="deleteExpenseAction"
				>
					<template #item.name="{ item }">
						{{ item.name }}
					</template>
					<template #item.quantity="{ item }"> {{ item.quantity }} </template>
					<template #item.amount="{ item }">
						{{
							item.amount.toLocaleString($lang, {
								style: "currency",
								currency: shipment.currency.code,
							})
						}}
					</template>
				</common-table>
				<v-row class="mt-1 px-2 pb-4" align="center">
					<v-col>
						<v-btn
							v-if="isEditable && iAmOwner && !customsAgency"
							class="ma-0"
							block
							rounded
							color="primary"
							elevation="0"
							outlined
							@click="expenseDialog.show()"
						>
							<v-icon size="20" class="mr-1"> icon-plus </v-icon>
							{{ $t("add_item", { item: $t("expenses") }) }}
						</v-btn>
					</v-col>
					<v-col v-if="currentItem.expenses.length" class="text-right">
						<span class="pr-4">
							<span class="v-typography--body black--text">
								{{
									`${$t("total")}: ${totalExpenseAmount.toLocaleString($lang, {
										style: "currency",
										currency: shipment.currency.code,
									})} `
								}}
							</span>
						</span>
					</v-col>
				</v-row>

				<container-expense-dialog
					v-if="iAmOwner && !customsAgency"
					v-model="expenseDialog.visibility"
					:item="expenseDialog.item"
					:shipment="shipment"
					:container="currentItem"
					@saved="expenseDialog.saved"
					@deleted="expenseDialog.deleted"
				/>
			</v-container>
		</template>
	</ext-dialog>
</template>

<script>
import TitleValueListItem from "../../../partials/TitleValueListItem.vue";
import remote from "../../../../data/remote";
import uiMixin from "../../../../store/interfaces/ui.mixin";
import ContainerProductDialog from "./ContainerProductDialog.vue";
import ContainerExpenseDialog from "./ContainerExpenseDialog.vue";
import ExtDialog from "../../../partials/dialogs/ExtDialog.vue";
import { mapGetters } from "vuex";
import CommonTable from "../../../partials/CommonTable.vue";
import { pagination as paginationMixin } from "../../../mixins/utils.mixin";

export default {
	props: {
		value: Boolean,
		item: Object,
		shipment: Object,
		iAmOwner: Boolean,
		statusStyles: {
			type: Function,
			required: true,
		},
	},
	components: {
		TitleValueListItem,
		ContainerProductDialog,
		ContainerExpenseDialog,
		ExtDialog,
		CommonTable,
	},
	mixins: [uiMixin, paginationMixin],
	data() {
		return {
			currentItem: null,
			productDialog: {
				visibility: false,
				item: null,
				show: (item) => {
					this.productDialog.item = item;
					this.productDialog.visibility = true;
				},
				hide: () => {
					this.productDialog.item = null;
					this.productDialog.visibility = false;
				},
				saved: (_) => {
					this.loadItem(this.currentItem.id);
					this.productDialog.hide();
					this.$emit("changed");
				},
				deleted: (_) => {
					this.loadItem(this.currentItem.id);
					this.productDialog.hide();
					this.$emit("changed");
				},
			},
			expenseDialog: {
				visibility: false,
				item: null,
				show: (item) => {
					this.expenseDialog.item = item;
					this.expenseDialog.visibility = true;
				},
				hide: () => {
					this.expenseDialog.item = null;
					this.expenseDialog.visibility = false;
				},
				saved: (item) => {
					this.loadItem(this.currentItem.id);
					this.expenseDialog.hide();
					this.$emit("changed");
				},
				deleted: (_) => {
					this.loadItem(this.currentItem.id);
					this.expenseDialog.hide();
					this.$emit("changed");
				},
			},
		};
	},
	computed: {
		...mapGetters(["customsAgency", "company"]),

		productHeaders() {
			const headers = [
				{
					text: this.$t("name"),
					value: "name",
					sortable: false,
				},
				{
					text: this.$t("quantity"),
					value: "quantity",
					align: "end",
					sortable: false,
				},
				{
					text: this.$t("amount"),
					value: "amount",
					align: "end",
					sortable: false,
				},
			];

			if (this.iAmOwner) {
				headers.push({
					text: this.$t("actions"),
					value: "actions",
					align: "end",
					sortable: false,
				});
			}

			return headers;
		},

		expenseHeaders() {
			const headers = [
				{
					text: this.$t("name"),
					value: "name",
					sortable: false,
				},
				{
					text: this.$t("quantity"),
					value: "quantity",
					align: "end",
					sortable: false,
				},
				{
					text: this.$t("amount"),
					value: "amount",
					align: "end",
					sortable: false,
				},
			];

			if (this.iAmOwner) {
				headers.push({
					text: this.$t("actions"),
					value: "actions",
					align: "end",
					sortable: false,
				});
			}

			return headers;
		},
		iAmSenderCompany() {
			return this.shipment && this.company ? this.company.id === this.shipment.sender.company.referenceId : false;
		},
		isLandShipment() {
			return this.shipment.method === "land";
		},
		isEditable() {
			return ["reserved", "on_load"].includes(this.currentItem.status);
		},
		totalProductAmount() {
			if (this.currentItem) {
				let total = 0;
				for (const item of this.currentItem.products) total += item.quantity * item.amount;
				return total;
			} else return null;
		},
		totalExpenseAmount() {
			if (this.currentItem) {
				let total = 0;
				for (const item of this.currentItem.expenses) total += item.quantity * item.amount;
				return total;
			} else return null;
		},
		totalAmount() {
			if (this.totalProductAmount != null || this.totalExpenseAmount != null)
				return Number(this.totalProductAmount) + Number(this.totalExpenseAmount);
			else return null;
		},
	},
	watch: {
		value(val) {
			if (val) this.handleProps();
			else this.reset();
		},
	},
	methods: {
		deleteProductAction(item) {
			this.showConfirmDialog({
				message: this.$t("delete_confirm_message"),
				onConfirm: () => {
					this.showProgressDialog();
					remote.shipments.containers.products.delete(this.shipment.id, this.currentItem.id, item.id, {
						onSuccess: () => {
							this.hideProgressDialog();
							this.showSnackBar({
								message: this.$t("$message.deleted", {
									item: this.$t("product"),
								}),
							});
							this.loadItem(this.currentItem.id);
						},
						onFail: () => {
							this.hideProgressDialog();
						},
					});
				},
			});
		},

		deleteExpenseAction(item) {
			this.showConfirmDialog({
				message: this.$t("delete_confirm_message"),
				onConfirm: () => {
					this.showProgressDialog();
					remote.shipments.containers.expenses.delete(this.shipment.id, this.currentItem.id, item.id, {
						onSuccess: () => {
							this.hideProgressDialog();
							this.showSnackBar({
								message: this.$t("$message.deleted", {
									item: this.$t("expense"),
								}),
							});
							this.loadItem(this.currentItem.id);
						},
						onFail: () => {
							this.hideProgressDialog();
						},
					});
				},
			});
		},
		handleProps() {
			if (!this.currentItem && this.item && this.shipment) this.loadItem();
		},
		reset() {
			this.currentItem = null;
		},
		loadItem() {
			this.showProgressDialog();
			remote.shipments.containers.find(this.item.id, this.shipment.id, {
				onSuccess: (result) => {
					this.currentItem = result;
					this.hideProgressDialog();
				},
			});
		},
		closeAction() {
			this.$emit("input", false);
			this.$emit("closed", false);
		},
	},
	mounted() {
		this.handleProps();
	},
};
</script>
