<template>
	<v-container>
		<v-card class="ant-card py-2 px-4" elevation="0">
			<v-toolbar flat dense>
				<v-toolbar-title class="v-typography--title">
					{{ $t("attachments") }}
				</v-toolbar-title>
			</v-toolbar>
			<v-divider class="mb-4"></v-divider>

			<v-card-text class="pb-0 px-0">
				<common-table
					:headers="attachmentListHeaders"
					:items="proformaInvoice.attachments"
					:items-length="proformaInvoice.attachments.length"
					:hide-pagination="true"
					:entity-name="$t('attachments')"
				>
					<template #item.name="{ item }">
						<a @click.stop="handlePreviewFileAction(item)" class="primary--text underlined">
							{{ item.name }}
						</a>
					</template>
					<template v-slot:item.buttons="{ item }">
						<div class="d-flex align-center justify-end">
							<v-tooltip bottom open-delay="500">
								<template v-slot:activator="{ on }" v-if="item.note">
									<v-btn icon @click="attachmentNoteAction(item)" v-on="on">
										<v-icon small>icon-notebook</v-icon>
									</v-btn>
								</template>
								<span>{{ $t("note") }}</span>
							</v-tooltip>

							<v-tooltip bottom open-delay="500">
								<template v-slot:activator="{ on }">
									<v-btn
										icon
										:loading="item.id == downloadingAttachmentId"
										:disabled="item.id == downloadingAttachmentId"
										@click="attachmentDownloadAction(item)"
										v-on="on"
									>
										<v-icon small>icon-download</v-icon>
									</v-btn>
								</template>
								<span>{{ $t("download") }}</span>
							</v-tooltip>

							<v-tooltip bottom open-delay="500" v-if="getEditableStatus(item)">
								<template v-slot:activator="{ on }">
									<v-btn icon @click="attachmentDialog.show(item)" v-on="on">
										<v-icon small>icon-edit</v-icon>
									</v-btn>
								</template>
								<span>{{ $t("edit") }}</span>
							</v-tooltip>
						</div>
					</template>
				</common-table>
				<v-row
					class="mt-1 px-2"
					align="center"
					v-if="
						proformaInvoice.status != proformaStatus.Rejected &&
						proformaInvoice.status != proformaStatus.PendingApproval &&
						proformaInvoice.status != proformaStatus.Processed &&
						proformaInvoice.status != proformaStatus.Approved
					"
				>
					<v-col>
						<v-btn color="accent" outlined large elevation="0" block @click="attachmentDialog.show()">
							<v-icon left>icon-plus</v-icon>
							{{ $t("add_item", { item: $t("attachments") }) }}
						</v-btn>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
		<file-view-dialog v-model="fileViewDialogVisibility" :item="fileViewDialogItem" @closed="onFileViewDialogClosed" />

		<proforma-invoice-attachment-dialog
			v-model="attachmentDialog.visibility"
			:item="attachmentDialog.item"
			:proformaInvoice="proformaInvoice"
			:myRole="iAmSender ? 'sender' : 'receiver'"
			@saved="attachmentDialog.saved"
			@deleted="attachmentDialog.deleted"
		/>
	</v-container>
</template>

<script>
import remote from "../../../../data/remote";
import FileViewDialog from "../../../partials/dialogs/FileViewDialog.vue";
import ProformaInvoiceAttachmentDialog from "../dialogs/ProformaInvoiceAttachmentDialog";
import { mapGetters } from "vuex";
import uiMixin from "../../../../store/interfaces/ui.mixin";
import CommonTable from "../../../partials/CommonTable.vue";
import { pagination as paginationMixin } from "../../../mixins/utils.mixin";
import { proformaStatus } from "../../../../utils/enum";

export default {
	name: "ProformaInvoiceAttachments",
	props: { proformaInvoice: Object },
	components: {
		ProformaInvoiceAttachmentDialog,
		FileViewDialog,
		CommonTable,
	},
	mixins: [uiMixin, paginationMixin],
	data() {
		return {
			fileViewDialogVisibility: false,
			fileViewDialogItem: null,
			downloadingAttachmentId: null,
			attachmentListHeaders: [
				{
					text: this.$t("name"),
					value: "name",
					sortable: false,
				},
				{
					text: this.$t("type"),
					value: "file.type",
					sortable: false,
				},
				{
					value: "buttons",
					align: "right",
					sortable: false,
					width: 145,
				},
			],
			attachmentDialog: {
				visibility: false,
				item: null,
				show: (item) => {
					this.attachmentDialog.item = item;
					this.attachmentDialog.visibility = true;
				},
				hide: () => {
					this.attachmentDialog.item = null;
					this.attachmentDialog.visibility = false;
				},
				saved: (created) => {
					this.attachmentDialog.hide();
					this.showSnackBar({
						message: this.$t(created ? "$message.created" : "$message.updated", {
							item: this.$t("attachment"),
						}),
					});
					this.$emit("changed");
				},
				deleted: () => {
					this.attachmentDialog.hide();
					this.showSnackBar({
						message: this.$t("$message.deleted", {
							item: this.$t("attachment"),
						}),
					});
					this.$emit("changed");
				},
			},
		};
	},
	computed: {
		...mapGetters(["company"]),
		iAmSender() {
			return this.company.id === this.proformaInvoice.sender.companyId;
		},
		proformaStatus() {
			return proformaStatus;
		},
	},
	methods: {
		handlePreviewFileAction(item) {
			if (!item || !item.file || !item.file.key) {
				console.error("Invalid file object passed:", item);
				return;
			}

			remote.files.get(item.file.key, {
				onSuccess: (result) => {
					const fileBlob = new Blob([result]);
					const fileUrl = window.URL.createObjectURL(fileBlob);
					this.showFileViewDialog({
						url: fileUrl,
						type: item.file.type,
						name: item.name,
					});
				},
			});
		},
		showFileViewDialog(file) {
			this.fileViewDialogItem = file;
			this.fileViewDialogVisibility = true;
		},
		hideFileViewDialog() {
			this.fileViewDialogItem = null;
			this.fileViewDialogVisibility = false;
		},
		onFileViewDialogClosed() {
			this.hideFileViewDialog();
		},
		attachmentNoteAction(attachment) {
			this.showAlertDialog({
				title: this.$t("note"),
				message: attachment.note,
				buttonText: this.$t("hide"),
			});
		},
		attachmentDownloadAction(attachment) {
			this.downloadingAttachmentId = attachment.id;
			remote.files.get(attachment.file.key, {
				onSuccess: (result) => {
					this.downloadingAttachmentId = null;
					const url = window.URL.createObjectURL(new Blob([result]));
					const link = document.createElement("a");
					link.href = url;
					link.setAttribute("download", attachment.file.key);
					document.body.appendChild(link);
					link.click();
					link.remove();
				},
				onFail: (_) => (this.downloadingAttachmentId = null),
			});
		},
		getEditableStatus(attachment) {
			return (
				attachment.ownerId == this.company.id &&
				this.proformaInvoice.status != proformaStatus.PendingApproval &&
				this.proformaInvoice.status != proformaStatus.Rejected &&
				this.proformaInvoice.status != proformaStatus.Approved &&
				this.proformaInvoice.status != proformaStatus.Processed
			);
		},
	},
};
</script>
