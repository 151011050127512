<template>
	<ext-dialog
		v-model="dialogVisibility"
		:title="$t('save_as_document')"
		:primaryButtonText="$t('save')"
		@primaryButtonAction="onSave"
		:secondaryButtonText="$t('cancel')"
		@secondaryButtonAction="hide"
		@closed="hide"
	>
		<template #body>
			<v-form ref="form" v-model="valid" @submit.prevent>
				<span> {{ $t("document_save_info") }} </span>
				<v-radio-group v-model="isSaveAsNew" :row="true">
					<v-radio
						:value="false"
						:label="$t('edit_document')"
						class="ml-2"
						on-icon="icon-radio-on"
						off-icon="icon-radio-off"
						:disabled="existingDocs.length === 0"
					/>
					<v-radio
						:value="true"
						:label="$t('document_overwrite')"
						class="ml-2"
						on-icon="icon-radio-on"
						off-icon="icon-radio-off"
					/>
				</v-radio-group>

				<v-select
					:items="existingDocs"
					item-text="name"
					item-value="_id"
					:label="$t('select_document_to_overwrite')"
					v-model="selectedDocToOverride"
					v-if="!isSelectDisabled"
					:rules="selectRules"
					hide-details
					outlined
					dense
					class="mb-2"
					append-icon="icon-angle-down"
					:menu-props="{ offsetY: true }"
				>
					<template v-slot:item="{ item }">
						{{ item.name }}
					</template>
				</v-select>

				<v-text-field
					v-if="isSaveAsNew"
					v-model="fileName"
					:label="$t('document_name')"
					:rules="nameRules"
					dense
					outlined
					class="mb-2"
					@keydown.enter.prevent="onSave"
				/>

				<v-checkbox
					v-model="attachToProforma"
					class="mt-0"
					:label="$t('save_pdf_to_attachments')"
					on-icon="icon-checkbox-checked"
					off-icon="icon-checkbox-unchecked"
				/>

				<v-text-field
					v-if="attachToProforma"
					v-model="pdfName"
					:label="$t('pdf_name')"
					dense
					outlined
					class="mb-2"
					@keydown.enter.prevent="onSave"
				/>
			</v-form>
		</template>
	</ext-dialog>
</template>

<script>
import ExtDialog from "../../../partials/dialogs/ExtDialog.vue";

export default {
	name: "SavePdfDialog",
	components: { ExtDialog },
	props: {
		visibility: { type: Boolean, default: false },
		existingDocs: {
			type: Array,
			default: () => [],
		},
		initialSelectedDocId: {
			type: [String, Number],
			default: null,
		},
	},
	data() {
		return {
			dialogVisibility: this.visibility,
			valid: false,
			fileName: "",
			pdfName: "",
			attachToProforma: true,

			selectedDocToOverride: null,
			isSaveAsNew: false,
		};
	},
	computed: {
		checkboxDisabled() {
			return this.existingDocs.length === 0;
		},
		isSelectDisabled() {
			return this.isSaveAsNew || this.existingDocs.length === 0;
		},
		selectRules() {
			return !this.selectedDocToOverride
				? [
						(v) =>
							!!v ||
							this.$t("$validation.required", {
								item: this.$t("select_document_to_overwrite"),
							}),
				  ]
				: [];
		},
		nameRules() {
			return this.isSaveAsNew
				? [
						(v) =>
							!!v ||
							this.$t("$validation.required", {
								item: this.$t("file_name"),
							}),
				  ]
				: [];
		},
	},
	watch: {
		visibility(newVal) {
			this.dialogVisibility = newVal;
			if (newVal) {
				this.initializeDialog();
			} else {
				this.resetDialog();
			}
		},
		dialogVisibility(newVal) {
			this.$emit("update:visibility", newVal);
		},
		existingDocs(newDocs) {
			if (newDocs.length === 0) {
				this.isSaveAsNew = true;
				this.selectedDocToOverride = null;
			} else {
				if (!this.initialSelectedDocId) {
					this.isSaveAsNew = false;
				}
				if (newDocs.length > 0 && !this.isSaveAsNew) {
					if (this.initialSelectedDocId) {
						const exists = newDocs.some((doc) => doc._id === this.initialSelectedDocId);
						this.selectedDocToOverride = exists ? this.initialSelectedDocId : null;
					} else {
						this.selectedDocToOverride = null;
					}
				}
			}
		},
	},
	mounted() {
		if (this.dialogVisibility) {
			this.initializeDialog();
		}
	},
	methods: {
		hide() {
			this.dialogVisibility = false;
			this.resetDialog();
		},
		resetDialog() {
			this.fileName = "";
			this.attachToProforma = true;
			this.pdfName = "";
			this.isSaveAsNew = this.existingDocs.length === 0;
			this.selectedDocToOverride = this.initialSelectedDocId || null;
			if (this.$refs.form) {
				this.$refs.form.resetValidation();
			}
		},

		initializeDialog() {
			if (this.existingDocs.length === 0) {
				this.isSaveAsNew = true;
				this.selectedDocToOverride = null;
			} else if (this.initialSelectedDocId) {
				this.selectedDocToOverride = this.initialSelectedDocId;
				this.isSaveAsNew = false;
			} else {
				this.isSaveAsNew = false;
				this.selectedDocToOverride = null;
			}
		},
		onSave() {
			const isValid = this.$refs.form.validate();
			if (!isValid) {
				return;
			}

			if (this.isSaveAsNew) {
				this.$emit("saved", {
					action: "save-as-new",
					fileName: this.fileName,
					attach: this.attachToProforma,
					pdfName: this.pdfName,
				});
			} else {
				this.$emit("saved", {
					action: "override",
					docId: this.selectedDocToOverride,
					attach: this.attachToProforma,
					pdfName: this.pdfName,
				});
			}

			this.hide();
		},
	},
};
</script>
