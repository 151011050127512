<template>
	<landing-page-template>
		<landing-pricing id="pricing" :isMobile="isMobile" :isTablet="isTablet" />
	</landing-page-template>
</template>

<script>
import uiMixin from "../../../../store/interfaces/ui.mixin";
import LandingPageTemplate from "../../../templates/LandingPageTemplate.vue";
import LandingPricing from "../partial/LandingPricing.vue";

export default {
	components: {
		LandingPageTemplate,
		LandingPricing,
	},
	mixins: [uiMixin],
};
</script>
