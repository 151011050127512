<template>
	<div class="fill-height">
		<v-app-bar app color="white" elevation="1" v-if="appBarVisibility">
			<v-app-bar-nav-icon
				v-if="corporation && isMobile"
				@click.stop="$store.state.ui.navigationDrawer.state = !$store.state.ui.navigationDrawer.state"
			>
				<v-icon>icon-menu</v-icon>
			</v-app-bar-nav-icon>

			<v-toolbar-title>
				<!-- <slot name="title" /> -->

				<router-link class="text-accent" to="/home">
					<v-img max-width="134" fit src="@/assets/antrego_black_logo.png" alt="Antrego Logo" />
				</router-link> </v-toolbar-title
			>​

			<v-spacer />
			<v-toolbar-items>
				<slot name="buttons" />
				<!-- <v-text-field
					v-if="showSearch"
					v-model="search"
					append-icon="icon-cross"
					label="Search..	"
					single-line
					density="compact"
					variant="outlined"
					class="d-lex justify-center align-center"
					hide-details
					@click:append="toggleSearch"
				/>
				<v-btn plain icon @click="toggleSearch">
					<v-icon small>icon-search</v-icon>
				</v-btn> -->
				<v-btn class="mr-2" color="primary" plain icon @click="changeLanguageDialog.show">
					<v-icon>icon-translate</v-icon>
				</v-btn>
				<user-menu @showFeedbackDialog="feedbackDialog.show" />
				<!-- <user-menu @showFeedbackDialog="feedbackDialog.show" @showSettingDialog="settingsDialog.show" /> -->
			</v-toolbar-items>

			<template v-slot:extension v-if="hasAppBarExtension">
				<slot name="appBarExtension" />
			</template>
		</v-app-bar>
		<v-container class="pt-0">
			<slot name="content" />
		</v-container>

		<feedback-dialog v-model="feedbackDialog.visibility" @closed="feedbackDialog.hide" />

		<!-- <settings-dialog
			v-model="settingsDialog.visibility"
			@saved="settingsDialog.saved"
			@closed="settingsDialog.closed"
		/> -->
		<change-language-dialog v-model="changeLanguageDialog.visibility" @closed="changeLanguageDialog.hide" />
	</div>
</template>
<!-- USAGE -->
<!--
  <page-template>
    <template slot="title">page title slot</template>
    <template slot="buttons">menu buttons slot</template>
    <template slot="content">
      content slot
    </template>
  </page-template>
-->
​
<script>
import { mapState } from "vuex";
import UserMenu from "./partials/UserMenu.vue";
import uiMixin from "../../store/interfaces/ui.mixin";
// import SettingsDialog from "../partials/dialogs/SettingsDialog.vue";
import ChangeLanguageDialog from "../partials/dialogs/ChangeLanguageDialog.vue";
import ExtDialog from "../partials/dialogs/ExtDialog.vue";
import FeedbackDialog from "../partials/FeedbackDialog.vue";

export default {
	props: { hideAppBar: Boolean | null, hasAppBarExtension: Boolean },
	mixins: [uiMixin],
	components: { UserMenu, ExtDialog, FeedbackDialog, ChangeLanguageDialog }, // SettingsDialog

	data() {
		return {
			changeLanguageDialog: {
				visibility: false,
				show: () => {
					this.changeLanguageDialog.visibility = true;
				},
				hide: () => {
					this.changeLanguageDialog.visibility = false;
				},
			},
			search: "",
			showSearch: false,

			feedbackDialog: {
				visibility: false,
				show: () => {
					this.feedbackDialog.visibility = true;
				},
				hide: () => {
					this.feedbackDialog.visibility = false;
				},
			},
			// settingsDialog: {
			// 	visibility: false,
			// 	show: () => (this.settingsDialog.visibility = true),
			// 	hide: () => (this.settingsDialog.visibility = false),
			// 	saved: () => {
			// 		this.settingsDialog.hide();
			// 		location.reload();
			// 	},
			// 	closed: () => {
			// 		return this.settingsDialog.hide();
			// 	},
			// },
		};
	},
	computed: {
		...mapState(["corporation", "employee"]),
		isMobile() {
			return this.$vuetify.breakpoint.mdAndDown;
		},
		appBarVisibility() {
			return this.hideAppBar == null ? true : !this.hideAppBar;
		},
	},

	methods: {
		toggleSearch() {
			this.showSearch = !this.showSearch;
			if (!this.showSearch) this.search = "";
		},
	},
};
</script>
