<template>
	<v-container class="py-0 mb-4">
		<v-card elevation="0" class="ant-card px-4 pt-2 pb-4">
			<v-toolbar flat dense class="pl-0">
				<v-toolbar-title class="v-typography--title">
					{{ $t("products") }}
				</v-toolbar-title>
			</v-toolbar>

			<v-divider class="mb-6 mt-1" />

			<common-table
				:headers="productListHeaders"
				:items="mergedProducts"
				:hide-pagination="true"
				:entity-name="$t('products')"
			>
				<template #item.name="{ item }">
					<span class="primary--text underlined" @click="showProductContainers(item)">
						{{ item.name }}
					</span>
				</template>

				<template #item.price="{ item }">
					{{ formatCurrency(item.amount) }}
				</template>

				<template #item.quantity="{ item }"> {{ item.quantity }} {{ item.unitCode }} </template>

				<template #item.total_amount="{ item }">
					<div class="d-flex justify-end">
						{{ formatCurrency(item.amount * item.quantity) }}
					</div>
				</template>
			</common-table>
			<v-divider class="my-4" />

			<div v-if="mergedProducts.length > 0" class="mt-4">
				<v-row v-if="totalSum != 0" align="center" class="px-4 pb-1" justify="space-between">
					<v-col cols="auto" class="d-flex align-center">
						<v-icon color="black" class="mr-2">icon-money</v-icon>
						<span class="v-typography--body1">{{ $t("total_amount") }}</span>
					</v-col>
					<v-col cols="auto" class="text-right">
						<span class="text-lg font-weight-bold">
							{{ formatCurrency(totalSum) }}
						</span>
					</v-col>
				</v-row>
			</div>
		</v-card>

		<product-containers-dialog
			v-model="productContainersDialog.visible"
			:product="productContainersDialog.product"
			:shipment="shipment"
			@select-container="emitShowContainer"
		/>
	</v-container>
</template>

<script>
import CommonTable from "../../../partials/CommonTable.vue";
import ProductContainersDialog from "../dialog/ProductContainersDialog.vue";

export default {
	name: "Products",
	components: {
		CommonTable,
		ProductContainersDialog,
	},
	props: {
		shipment: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			productListHeaders: [
				{ text: this.$t("name"), value: "name", sortable: false },
				{ text: this.$t("amount"), value: "price", sortable: false },
				{ text: this.$t("quantity"), value: "quantity", sortable: false },
				{ text: this.$t("total_amount"), value: "total_amount", sortable: false },
			],
			productContainersDialog: {
				visible: false,
				product: null,
			},
		};
	},
	computed: {
		productsObject() {
			if (!this.shipment || !this.shipment.containers || !this.shipment.containers.length) {
				return [];
			}
			const map = new Map();
			for (const container of this.shipment.containers) {
				if (container.products && container.products.length) {
					for (const product of container.products) {
						const key = `${product.name.default}-${product.amount}-${product.unitCode}`;

						if (!map.has(key)) {
							map.set(key, {
								name: product.name,
								amount: product.amount,
								quantity: product.quantity,
								unitCode: product.unitCode,
							});
						} else {
							const existing = map.get(key);
							existing.quantity += product.quantity;
							map.set(key, existing);
						}
					}
				}
			}
			return Array.from(map.values());
		},

		mergedProducts() {
			if (!this.shipment || !this.shipment.containers || !this.shipment.containers.length) {
				return [];
			}

			const map = new Map();
			for (const container of this.shipment.containers) {
				if (container.products && container.products.length) {
					for (const product of container.products) {
						const productName = product.name[this.$i18n.locale] || product.name._default;
						const key = `${productName}-${product.amount}-${product.unitCode}`;

						if (!map.has(key)) {
							map.set(key, {
								name: productName,
								amount: product.amount,
								quantity: product.quantity,
								unitCode: product.unitCode,
							});
						} else {
							const existing = map.get(key);
							existing.quantity += product.quantity;
							map.set(key, existing);
						}
					}
				}
			}
			return Array.from(map.values());
		},
		totalSum() {
			return this.mergedProducts.reduce((sum, product) => {
				return sum + product.quantity * product.amount;
			}, 0);
		},
	},
	watch: {
		productsObject: {
			handler(newValue) {
				this.$emit("merged-products-updated", newValue);
			},
			immediate: true,
		},
	},
	methods: {
		formatCurrency(value) {
			if (!this.shipment || !this.shipment.currency) return value;
			return value.toLocaleString(this.$lang, {
				style: "currency",
				currency: this.shipment.currency.code,
			});
		},
		showProductContainers(product) {
			this.productContainersDialog.product = product;
			this.productContainersDialog.visible = true;
		},
		emitShowContainer(container) {
			this.$emit("show-container", container);
		},
	},
};
</script>

<style scoped>
.no-data-message {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: rgba(0, 0, 0, 0.54);
}
.icon-empty-state {
	margin-bottom: 10px;
}
.text-center {
	text-align: center;
}
.primary--text.underlined {
	cursor: pointer;
	text-decoration: underline;
}
</style>
