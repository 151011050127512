<template>
	<v-main>
		<div class="d-flex flex-column" style="min-height: 100vh; width: 100%">
			<landing-header
				@goTo="goTo"
				@switchDrawerVisibility="switchDrawerVisibility"
				:showDrawer="showDrawer"
				:isMobile="isMobile"
				:isLanding="false"
			/>
			<landing-drawer-menu
				:value="showDrawer"
				@input="switchDrawerVisibility"
				@goTo="goTo"
				@switchDrawerVisibility="switchDrawerVisibility"
			/>

			<div class="flex-grow-1 d-flex align-center justify-center mt-16 pt-12 pb-10">
				<slot />
			</div>

			<landing-footer @goTo="goTo" :isMobile="isMobile" />
		</div>
	</v-main>
</template>

<script>
import LandingHeader from "../pages/landing/partial/LandingHeader.vue";
import LandingFooter from "../pages/landing/partial/LandingFooter.vue";
import LandingDrawerMenu from "../pages/landing/partial/LandingDrawerMenu.vue";
import uiMixin from "../../store/interfaces/ui.mixin";

export default {
	mixins: [uiMixin],
	components: {
		LandingHeader,
		LandingDrawerMenu,
		LandingFooter,
	},
	data() {
		return {
			showDrawer: false,
		};
	},
	methods: {
		goTo(tag) {
			this.$nextTick(() => document.getElementById(tag).scrollIntoView({ behavior: "smooth" }));
		},
		switchDrawerVisibility(val) {
			this.showDrawer = val;
		},
	},
};
</script>
