import Auth from "./auth.api";
import ProformaInvoiceModel from "./proforma_invoice/proforma_invoice.model";
import FileModule from "./file.api";
import CompanyModel from "./membership/company.model";
import CorporationModel from "./membership/corporation.model";
import CustomsAgencyModel from "./membership/customs_agency.model";
import EmployeeModel from "./membership/employee.model";
import InvitationModel from "./membership/invitation.model";
import WarehouseModel from "./membership/warehouse.model";
import SocialMediaModel from "./membership/social_media.model";
import PartnerModel from './membership/partner.model';
import ProductModel from "./stock/product.model";
import ProductGroupModel from "./stock/product_group.model";
import StructureApi from "./structure.api";
import ShipmentModel from "./shipment/shipment.model";
import PaymentModel from "./finance/payment.model";
import AntProductModel from "./antrego/ant_product.model";
import UtilitiesApi from "./utilities.api";
import FeedbackModel from "./antrego/feedback.model";
import SystemEnvironmentModel from "./antrego/system_environment.model";

const auth = new Auth();
const files = new FileModule();
const structures = new StructureApi();
const utilities = new UtilitiesApi();
const productModel = new ProductModel();
const productGroupModel = new ProductGroupModel();
const proformaInvoicesModel = new ProformaInvoiceModel();
const shipmentModel = new ShipmentModel();
const companyModel = new CompanyModel();
const corporationModel = new CorporationModel();
const customsAgencyModel = new CustomsAgencyModel();
const employeeModel = new EmployeeModel();
const invitationModel = new InvitationModel();
const warehouseModel = new WarehouseModel();
const socialMediaModel = new SocialMediaModel();
const partnerModel = new PartnerModel();
const paymentModel = new PaymentModel();
const antProductModel = new AntProductModel();
const feedbackModel = new FeedbackModel();
const systemEnvironmentModel = new SystemEnvironmentModel();

const wsModules = [
	structures,
	utilities,
	partnerModel,
	productModel,
	productGroupModel,
	proformaInvoicesModel,
	shipmentModel,
	companyModel,
	corporationModel,
	customsAgencyModel,
	employeeModel,
	invitationModel,
	warehouseModel,
	socialMediaModel,
	paymentModel,
	antProductModel,
	feedbackModel,
	systemEnvironmentModel,
];

export default {
	/**
	 * @param { getAccessToken, getHttpInstance, getAuthUrlRoot, getWsUrlRoot } value
	 */
	setProvider(value) {
		auth.setProvider({
			getAccessToken: value.getAccessToken,
			getHttpInstance: value.getHttpInstance,
			getUrlRoot: value.getAuthUrlRoot,
		});
		files.setProvider({
			getAccessToken: value.getAccessToken,
			getHttpInstance: value.getHttpInstance,
			getUrlRoot: value.getFsUrlRoot,
		});
		for (const module of wsModules)
			module.setProvider({
				getAccessToken: value.getAccessToken,
				getHttpInstance: value.getHttpInstance,
				getUrlRoot: value.getWsUrlRoot,
			});
	},
	auth,
	files,
	structures,
	utilities,
	partners: partnerModel,
	products: productModel,
	productGroups: productGroupModel,
	proformaInvoices: proformaInvoicesModel,
	shipments: shipmentModel,
	employees: employeeModel,
	companies: companyModel,
	corporations: corporationModel,
	customsAgencies: customsAgencyModel,
	invitations: invitationModel,
	warehouses: warehouseModel,
	socialMedia: socialMediaModel,
	payment: paymentModel,
	antProduct: antProductModel,
	feedback: feedbackModel,
	systemEnvironment: systemEnvironmentModel,
};
