<template>
	<v-data-table
		:headers="headers"
		:items="items"
		show-expand
		:loading="loading"
		:items-per-page="-1"
		hide-default-footer
		item-key="id"
		disable-sort
		expand-icon="icon-angle-down"
	>
		<template v-slot:item.action="{ item }">
			<v-tooltip bottom open-delay="500">
				<template v-slot:activator="{ on }">
					<v-btn icon @click="rowAction(item)" v-on="on">
						<v-icon small>icon-zoom</v-icon>
					</v-btn>
				</template>
				<span>{{ $t("detail") }}</span>
			</v-tooltip>
		</template>
		<template v-slot:item.date="{ item }">
			{{ item.date ? item.date.format($t("$format.date")) : "" }}
		</template>
		<template v-slot:expanded-item="{ headers, item }">
			<div v-if="isMobile" :colspan="headers.length" class="pa-0">
				<v-card elevation="1" class="pa-2">
					<container-table :containers="item.containers" :showContentSummary="true" />
				</v-card>
			</div>
			<td v-else :colspan="headers.length" class="pa-0">
				<v-card elevation="1" class="pa-2">
					<container-table :containers="item.containers" :showContentSummary="true" />
				</v-card>
			</td>
		</template>
		<template v-slot:item.target="{ item }">
			{{ item.unloadingPort ? item.unloadingPort.name : item.unloadingPoint }}
		</template>
		<template #no-data>
			<div v-if="!loading" class="no-data-message text-center mt-2">
				<v-icon class="icon-empty-state" size="28" color="black"></v-icon>
				<v-col>
					<p class="font-weight-bold mb-1 black--text">
						{{ $t("no_entity", { entityName: $t("shipment") }) }}
					</p>
					<span>{{ $t("no_entity_message", { entityName: $t("shipment") }) }}</span>
				</v-col>
			</div>
		</template>
	</v-data-table>
</template>

<script>
import remote from "../../../../data/remote";
import ContainerTable from "../../shipment/partial/ContainerTable.vue";
import { mapGetters } from "vuex";

export default {
	props: { role: "sender" | "receiver" },
	components: { ContainerTable },
	data() {
		return {
			items: [],
			loading: true,
			expanded: [],
			headers: [],
		};
	},
	computed: {
		...mapGetters(["company", "customsAgency"]),
		oppositeRole() {
			return this.role == "sender" ? "receiver" : "sender";
		},
		isMobile() {
			return this.$vuetify.breakpoint.xsOnly;
		},
	},
	methods: {
		rowAction(item) {
			this.$router.push({ name: "shipment", params: { id: item.id } });
		},
		calculateTotalAmount(shipment) {
			let result = null;
			if (shipment.containers.length) {
				for (const container of shipment.containers) {
					if ((container.products.length || container.expenses.length) && result == null) result = 0;
					for (const product of container.products) result += product.quantity * product.amount;
					for (const expense of container.expenses) result += expense.quantity * expense.amount;
				}
			}
			return result;
		},
		prepareHeaders() {
			if (!this.customsAgency) {
				this.headers.push({
					text: this.$t(this.role == "sender" ? "receiver" : "sender"),
					value: `${this.role == "sender" ? "receiver" : "sender"}.company.name`,
					sortable: false,
					align: "start",
					width: "45%",
				});
			}

			if (this.customsAgency) {
				this.headers.push({
					text: this.$t("sender"),
					value: "sender.company.name",
					sortable: false,
					align: "start",
					width: "22.5%",
				});
				this.headers.push({
					text: this.$t("receiver"),
					value: "receiver.company.name",
					sortable: false,
					align: "start",
					width: "22.5%",
				});
			}

			this.headers.push({
				text: this.$t("target"),
				value: "target",
				sortable: false,
				width: "25%",
			});
			this.headers.push({
				text: this.$t("date"),
				value: "date",
				sortable: false,
				width: "15%",
			});
			if (this.company) {
				this.headers.push({
					text: this.$t("total_amount"),
					value: "totalAmount",
					sortable: false,
					align: "end",
					width: "15%",
				});
			}
			this.headers.push({
				text: "",
				value: "action",
				sortable: false,
				width: "1",
			});
			this.headers.push({ text: "", value: "data-table-expand", width: "1" });
		},
		loadData() {
			this.loading = true;
			remote.shipments.filter({
				filter: {
					role: this.role,
					accessibilities: ["present"],
				},
				onSuccess: (result) => {
					this.items = result.items
						.filter((s) => s.bookingCode && s.containers.length)
						.sort((s1, s2) => {
							const s1MinArrivalDay = s1.containers.length
								? s1.containers.sort((c1, c2) => (c1.estimatedArrivalDay < c2.estimatedArrivalDay ? -1 : 1))[0]
										.estimatedArrivalDay
								: null;
							const s2MinArrivalDay = s2.containers.length
								? s2.containers.sort((c1, c2) => (c1.estimatedArrivalDay < c2.estimatedArrivalDay ? -1 : 1))[0]
										.estimatedArrivalDay
								: null;
							if (s1MinArrivalDay && s2MinArrivalDay) return s1MinArrivalDay < s2MinArrivalDay ? -1 : 1;
							else s1MinArrivalDay ? -1 : 1;
						});
					for (const shipment of result.items) {
						const amount = this.calculateTotalAmount(shipment);
						if (amount != null)
							shipment.totalAmount = amount.toLocaleString(this.$lang, {
								style: "currency",
								currency: shipment.currency.code,
							});
						else shipment.totalAmount = "-";
					}
					this.loading = false;
				},
			});
		},
	},
	created() {
		this.prepareHeaders();
		this.loadData();
	},
};
</script>
