<template>
	<landing-page-template>
		<landing-contact id="contact" :isMobile="isMobile" />
	</landing-page-template>
</template>

<script>
import uiMixin from "../../../../store/interfaces/ui.mixin";
import LandingPageTemplate from "../../../templates/LandingPageTemplate.vue";
import LandingContact from "../partial/LandingContact.vue";

export default {
	components: {
		LandingPageTemplate,
		LandingContact,
	},
	mixins: [uiMixin],
};
</script>
